import { decryptData } from "src/dataFetcher/chiper";
import { encConfig } from "src/dataFetcher/config";
import getData from "src/dataFetcher/getDataFetcher";
import postData from "src/dataFetcher/postDataFetcher";
import putData from "src/dataFetcher/putDataFetcher";
import { backendBaseUrl } from "src/utils/backendUrls";

const extractData = (result) => {
  if (encConfig.encryption.decrypt) {
    return decryptData(result);
  }
  return result;
};

export async function getBlockedCustomersList(userInfo, params) {
  const headers = {
    tenant_id: userInfo?.tenant_id,
    user_id: userInfo?.user_id,
  };
  if (userInfo?.is_demo_user) {
    headers.is_demo_user = true;
  }
  const token = userInfo?.accessToken;
  const result = await getData(
    `${backendBaseUrl}tenant/${userInfo?.tenant_name}/return/block_customer/info`,
    params,
    headers,
    token
  );
  return result;
}

export async function addBlockedCustomer(userInfo, data) {
  const headers = {
    tenant_id: userInfo?.tenant_id,
    user_id: userInfo?.user_id,
  };
  if (userInfo?.is_demo_user) {
    headers.is_demo_user = true;
  }
  const token = userInfo?.accessToken;
  const result = await putData(
    `${backendBaseUrl}tenant/${userInfo?.tenant_name}/return/block_customer/info`,
    headers,
    data,
    token
  );
  return JSON.parse(extractData(result.data));
}

export async function unblockBlockedCustomer(userInfo, data) {
  const headers = {
    tenant_id: userInfo?.tenant_id,
    user_id: userInfo?.user_id,
  };
  if (userInfo?.is_demo_user) {
    headers.is_demo_user = true;
  }
  const token = userInfo?.accessToken;
  const result = await postData(
    `${backendBaseUrl}tenant/${userInfo?.tenant_name}/return/block_customer/info`,
    headers,
    data,
    token
  );
  return JSON.parse(extractData(result.data));
}
export async function getCustomersByEmail(userInfo, params) {
  const headers = {
    tenant_id: userInfo?.tenant_id,
    user_id: userInfo?.user_id,
  };
  if (userInfo?.is_demo_user) {
    headers.is_demo_user = true;
  }
  const token = userInfo?.accessToken;
  const result = await getData(
    `${backendBaseUrl}tracking/${userInfo?.tenant_name}/returns/customer_info`,
    params,
    headers,
    token
  );
  return result;
}
