import React, { useEffect, useState } from "react";
import { Stepper, Step, StepLabel, Button, Box, Stack } from "@mui/material";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import { styled } from "@mui/material/styles";
import WarehouseStepper from "./WarehouseStepper";
import CarrierStepper from "./CarrierStepper";
import BoxConfigStepper from "./BoxConfigStepper";
import LabelConfigStepper from "./LabelConfigStepper";
import EmailConfigStepper from "./EmailConfigStepper";
import PaidLabelConfigStepper from "./PaidLabelConfigStepper";
import { useDispatch, useSelector } from "react-redux";
import ConfigPreview from "./ConfigPreview";
import {
  resetStepperForm,
  updateStep,
} from "src/features/returns/stepperFormSlice";
import { LABELS } from "../Labels";

const steps = [
  "Warehouse Address",
  "Carrier & Mail Class",
  "Box Configuration",
  "Label Configuration",
  "Paid Label Configuration",
  "Email Configuration",
];

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#1976d2",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#1976d2",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: "#eaeaf0",
    borderTopWidth: 5,
    borderRadius: 5,
  },
}));

// Custom StepIcon to remove the number
const QontoStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  color: ownerState.active || ownerState.completed ? "#1976d2" : "#eaeaf0",
  display: "flex",
  height: 22,
  alignItems: "center",
}));

const QontoStepIcon = (props) => {
  const { active, completed } = props;
  return (
    <QontoStepIconRoot ownerState={{ completed, active }}>
      <div className="QontoStepIcon-circle" />
    </QontoStepIconRoot>
  );
};

const FormStepper = ({
  customerManagedReturn,
  savedLabel,
  handleEditPrepaidLabelConfiguration,
  setHasBeenSaved,
  step = 0,
  openEditTemplateUI,
  setOpenEditTemplateUI,
  emailTemplateItemData,
  setEmailTemplateItemData,
  logoFile,
  setLogoFile,
  emailTemplateLayout,
  setEmailTemplateLayout,
  emailTemplateContent,
  setEmailTemplateContent,
  emailTemplateSubject,
  setEmailTemplateSubject,
  refresh,
  setRefresh,
}) => {
  const [activeStep, setActiveStep] = useState(step);

  const handleNext = () => setActiveStep((prevStep) => prevStep + 1);
  const handleBack = () => setActiveStep((prevStep) => prevStep - 1);

  const handleStepClick = (stepIndex) => {
    setActiveStep(stepIndex);
  };

  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <WarehouseStepper handleNext={handleNext} handleBack={handleBack} />
        );
      case 1:
        return (
          <CarrierStepper handleNext={handleNext} handleBack={handleBack} />
        );
      case 2:
        return (
          <BoxConfigStepper handleNext={handleNext} handleBack={handleBack} />
        );
      case 3:
        return (
          <LabelConfigStepper handleNext={handleNext} handleBack={handleBack} />
        );
      case 4:
        return (
          <PaidLabelConfigStepper
            handleNext={handleNext}
            handleBack={handleBack}
          />
        );
      case 5:
        return (
          <EmailConfigStepper
            handleNext={handleNext}
            handleBack={handleBack}
            customerManagedReturn={customerManagedReturn}
            setHasBeenSaved={setHasBeenSaved}
            openEditTemplateUI={openEditTemplateUI}
            setOpenEditTemplateUI={setOpenEditTemplateUI}
            emailTemplateItemData={emailTemplateItemData}
            setEmailTemplateItemData={setEmailTemplateItemData}
            logoFile={logoFile}
            setLogoFile={setLogoFile}
            emailTemplateLayout={emailTemplateLayout}
            setEmailTemplateLayout={setEmailTemplateLayout}
            emailTemplateContent={emailTemplateContent}
            setEmailTemplateContent={setEmailTemplateContent}
            emailTemplateSubject={emailTemplateSubject}
            setEmailTemplateSubject={setEmailTemplateSubject}
            refresh={refresh}
            setRefresh={setRefresh}
          />
        );
      case 6:
        return (
          <ConfigPreview
            handleEditPrepaidLabelConfiguration={
              handleEditPrepaidLabelConfiguration
            }
            resetToInitialStep={handleStepClick}
          />
        );
      default:
        return "Unknown Step";
    }
  };

  return (
    <Box
      width={"100%"}
      sx={{
        backgroundColor: "white",
        border: "1px solid #D9D9D9",
        position: "relative",
      }}
    >
      {activeStep == 6 && (
        <Box sx={{ padding: 0 }}>{renderStepContent(activeStep)}</Box>
      )}
      {activeStep !== 6 && (
        <Stack
          alignItems={"center"}
          gap={2}
          width={"100%"}
          paddingTop={4}
          paddingX={1}
        >
          <Stepper
            activeStep={activeStep}
            alternativeLabel
            connector={<QontoConnector />}
            sx={{
              width: "60%",
            }}
          >
            {steps.map((label, index) => (
              <Step key={label} onClick={() => handleStepClick(index)}>
                {/* <Step key={label}> */}
                {/* <StepLabel StepIconComponent={QontoStepIcon}>{label}</StepLabel>  */}
              </Step>
            ))}
          </Stepper>

          <Box sx={{ width: "100%" }}>{renderStepContent(activeStep)}</Box>
        </Stack>
      )}
    </Box>
  );
};

export default FormStepper;
