import { Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { getUserInfo } from "src/features/user/userState";
import { useAppDispatch, useAppSelector } from "src/hooks/hooks";
import { htmlReturnTemplateTextList } from "src/utils/convertHtmlToText";
import { retrieveLogoUrl } from "src/features/config/configApi";
import typography from "src/theme/typography";
import { getEmailTemplatesList } from "src/features/returnConfiguration/emailTemplatesApi";
import {
  decrementAwaitingApiCounter,
  incrementAwaitingApiCounter,
} from "src/features/ui/uiState";
import { filterImgs } from "src/utils/emailTemplates/functions";

const PreviewTemplate = ({
  template_name,
  logoFile,
  isRenderingFromModal,
  emailTemplateContent,
  emailTemplateSubject,
}) => {
  const dispatch = useAppDispatch();
  const userInfo = useAppSelector(getUserInfo);

  const [emailTemplateData, setEmailTemplateData] = useState("");

  const mergeHtmlLayoutAndNewContent = (layout, content) => {
    return layout.replace("[replace_this_by_content]", content);
  };

  const addPaddingToOl = (htmlContent) => {
    const styleTag = "<style>ol,ul { padding-left: 50px; }</style>";
    return styleTag + htmlContent;
  };

  useEffect(() => {
    const populateEmailTemplate = async () => {
      dispatch(incrementAwaitingApiCounter());

      try {
        const response = await getEmailTemplatesList(userInfo);
        if (response.statusCode === 200) {
          if (response.data.length > 0) {
            const emailTemplateData = response.data.find(
              (el) => el.template_name === template_name
            );
            if (emailTemplateData) {
              setEmailTemplateData({
                html_content: addPaddingToOl(
                  filterImgs(emailTemplateData?.html_content)
                ),
                subject: emailTemplateData?.subject,
              });
              dispatch(decrementAwaitingApiCounter());
              return;
            }
          }

          const defaultTemplate = (await htmlReturnTemplateTextList()).find(
            (el) => el.template_name === template_name
          );

          const htmlContent = defaultTemplate?.html_content.replace(
            "{{tracking_link}}",
            `/returns/${userInfo.tenant_name}/tracking`
          );
          const htmlLayout = defaultTemplate?.html_layout;
          setEmailTemplateData({
            html_content: addPaddingToOl(
              mergeHtmlLayoutAndNewContent(htmlLayout, htmlContent)
            ),
            subject: defaultTemplate?.subject,
          });

          dispatch(decrementAwaitingApiCounter());
        }
        dispatch(decrementAwaitingApiCounter());
      } catch (error) {
        dispatch(decrementAwaitingApiCounter());
      }
    };

    isRenderingFromModal && populateEmailTemplate();
  }, []);

  return (
    <Stack width={600} maxHeight={isRenderingFromModal && "80vh"}>
      <Typography
        fontSize={20}
        bgcolor={"#F2F4F7"}
        borderRadius={
          isRenderingFromModal ? "12px 0px 0px 0px" : "12px 12px 0px 0px"
        }
        paddingY={3}
        paddingX={3}
        fontFamily={typography.fontFamilySemiBold}
      >
        Subject:{" "}
        {isRenderingFromModal
          ? emailTemplateData.subject
          : emailTemplateSubject}
      </Typography>
      <Stack alignItems={"center"} bgcolor={"white"} paddingTop={3}>
        {emailTemplateData.html_content || emailTemplateContent ? (
          <>
            <img
              src={
                logoFile === "/assets/no-img.svg"
                  ? "/assets/no-img.svg"
                  : retrieveLogoUrl + logoFile
              }
              alt="logo"
              width={logoFile === "/assets/no-img.svg" ? 70 : 200}
            />
            <div
              dangerouslySetInnerHTML={{
                __html: isRenderingFromModal
                  ? addPaddingToOl(emailTemplateData.html_content)
                  : addPaddingToOl(emailTemplateContent),
              }}
            ></div>
          </>
        ) : (
          <div
            style={{
              height: "200px",
            }}
          ></div>
        )}
      </Stack>
    </Stack>
  );
};

export default PreviewTemplate;
