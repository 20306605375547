import useCustomerOrderInfo from "src/features/return_management/hooks/useCustomerOrderInfo";
import ReturnsLayout from "../portal/guestFlow/ReturnsLayout";
import { LoadingButton } from "@mui/lab";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import CreditScoreIcon from "@mui/icons-material/CreditScore";
import {
  convertUTCtoDateFormat,
  getCurrencySymbol,
  unixToDate,
} from "src/utils/core";
import RefundEstimateInfo from "../portal/components/RefundEstimateInfo";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  GetEstimateSummary,
  getOrderInfo,
  getOrderStatus,
  getReturnDetails,
} from "../returns/ReturnsApi";
import { getUserInfo } from "src/features/user/userState";
import { useAppSelector } from "src/hooks/hooks";
import CircularLoader from "src/loader/circular/CircularLoader";
import { useSelector } from "react-redux";
const {
  Typography,
  Box,
  Grid,
  Stepper,
  Step,
  StepLabel,
} = require("@mui/material");

const Preview = () => {
  const userInfo = useAppSelector(getUserInfo);
  const [trackingId, setTrackingId] = useState(null);
  const [returnDetails, setReturnDetails] = useState({});
  const [returnStatusHistory, setReturnStatusHistory] = useState([]);
  const [currentStatus, setCurrentStatus] = useState("");
  const [returnInitiated, setReturnInitiated] = useState("");
  const [orderDetails, setOrderDetails] = useState([]);
  const [lineItems, setLineItems] = useState([]);
  const [returnEstimate, setReturnEstimate] = useState({});
  const [refresh, setRefresh] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [images, setImages] = useState({});
  const [currency, setCurrency] = useState("");
  const shopifyImages = useSelector(
    (state) => state.shopifyImages.shopifyImages
  );
  const returnStatusHistory1 = [
    {
      _id: "668c1cfe4a7377b3405110a6",
      return_list_id: "668c1cfd4a7377b3405110a5",
      status: "Initiated",
      tenant_id: "668c1cfd4a7377b3405110a4",
      created_at: 1720458493,
      created_by: "66698d97c16986d35eb00013",
      created_source: "admin",
    },
    {
      _id: "668c200544431c56dd8fea3f",
      return_list_id: "668c1cfd4a7377b3405110a5",
      status: "Accepted",
      tenant_id: "668c1cfd4a7377b3405110a4",
      created_at: 1720459269,
      created_by: "66698d97c16986d35eb00013",
      created_source: "admin",
    },
    {
      _id: "668c2060ba9bac467c6ec62b",
      return_list_id: "668c1cfd4a7377b3405110a5",
      status: "Refunded",
      tenant_id: "668c1cfd4a7377b3405110a4",
      created_at: 1720459360,
      created_by: "66698d97c16986d35eb00013",
      created_source: "admin",
    },
  ];
  const trackingItems = [
    {
      id: 13757434888285,
      name: "The Collection Snowboard: Liquid",
      title: "The Collection Snowboard: Liquid",
      sku: 198913,
      price: "749.95",
      imgUrl:
        "https://cdn.shopify.com/s/files/1/0636/0298/3005/files/Main_b13ad453-477c-4ed1-9b43-81f3345adfd6.jpg?v=1716573549",
      fulfillment_line_item_id: 11856084664413,
      line_item_id: 13757434888285,
      quantity: 1,
      reason: "Ordered by Mistake",
      return_reason: "UNWANTED",
      customer_notes: "Wront Propduct",
      return_line_item_id: 20022853725,
    },
    {
      id: 13757434921053,
      name: "The Multi-managed Snowboard",
      title: "The Multi-managed Snowboard",
      sku: 895983,
      price: "629.95",
      imgUrl:
        "https://cdn.shopify.com/s/files/1/0636/0298/3005/files/Main_9129b69a-0c7b-4f66-b6cf-c4222f18028a.jpg?v=1716573546",
      fulfillment_line_item_id: 11856084697181,
      line_item_id: 13757434921053,
      quantity: 1,
      reason: "Wrong Size or Fit",
      return_reason: "UNWANTED",
      customer_notes: "Test Data",
      return_line_item_id: 20022886493,
    },
  ];
  const estimate = {
    return_items_amount: 1379.9,
    tax_amount: 0,
    shipping_maximum_refundable: 0,
  };
  const statuses = {
    Initiated: [
      {
        name: "Accepted",
        icon: (
          <AssignmentTurnedInIcon fontSize="10px" sx={{ color: "#D6D6D6" }} />
        ),
      },
      {
        name: "Refunded",
        icon: <CreditScoreIcon fontSize="10px" sx={{ color: "#D6D6D6" }} />,
      },
    ],
    Accepted: [
      {
        name: "Refunded",
        icon: <CreditScoreIcon fontSize="10px" sx={{ color: "#D6D6D6" }} />,
      },
    ],
  };
  const {
    // returnEstimate,
    returnInfo,
    trackingPortalConfig,
  } = useCustomerOrderInfo();

  const currency_symbol = getCurrencySymbol(currency);
  const location = useLocation();
  useEffect(() => {
    const fetchAllData = async () => {
      setIsLoading(true);
      const params = new URLSearchParams(location.search);
      const trackingId = params.get("tracking_id");
      setTrackingId(trackingId);

      try {
        if (trackingId) {
          const [orderStatusRes, returnDetailsRes] = await Promise.all([
            getOrderStatus(trackingId, userInfo),
            getReturnDetails(trackingId, userInfo),
          ]);

          if (orderStatusRes.statusCode === 200) {
            setReturnStatusHistory(orderStatusRes.data);
            setCurrentStatus(
              orderStatusRes.data[orderStatusRes.data.length - 1]?.status
            );
          }

          if (returnDetailsRes.statusCode === 200) {
            setReturnDetails(returnDetailsRes.data);
            setLineItems(returnDetailsRes.data?.line_items);

            const orderInfoRes = await getOrderInfo(
              returnDetailsRes.data?.order_id,
              userInfo
            );
            let currency = orderInfoRes.data.currency;
            setCurrency(currency);
            if (orderInfoRes.statusCode === 200) {
              const filteredOrders = orderInfoRes.data?.line_items
                .filter((item) =>
                  lineItems.some(
                    (lineItem) => lineItem.line_item_id === item.id
                  )
                )
                .map((item) => {
                  const lineItem = lineItems.find(
                    (lineItem) => lineItem.line_item_id === item.id
                  );
                  return {
                    ...item,
                    return_reason: lineItem?.reason,
                    customer_notes: lineItem?.customer_notes,
                  };
                });
              setOrderDetails(filteredOrders);
              setRefresh(true);
            }

            setReturnInitiated(
              convertUTCtoDateFormat(returnDetailsRes.data?.created_at)
            );

            let lineItemsArray = [];
            returnDetailsRes.data?.line_items?.forEach((item) => {
              if (item.return_line_item_id) {
                lineItemsArray.push({
                  line_item_id: item.return_line_item_id,
                  quantity: item.quantity,
                });
              }
            });

            const refundData = {
              order_id: returnDetailsRes.data?.order_id,
              return_id: returnDetailsRes.data?.return_id,
              refund_shipping: {
                amount: 0,
                currencyCode: 'USD'
              },
              line_items: lineItemsArray,
            };

            const estimateSummaryRes = await GetEstimateSummary(
              refundData,
              userInfo
            );
            if (estimateSummaryRes.statusCode === 200) {
              setReturnEstimate(estimateSummaryRes.data);
            }
          }
        } else {
          setReturnStatusHistory(returnStatusHistory1);
          setReturnInitiated("June 25, 2024");
          setOrderDetails(trackingItems);
          setReturnEstimate(estimate);
        }
      } catch (error) {
        console.error(error.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchAllData();
  }, [location, refresh]);

  useEffect(() => {
    if (orderDetails.length > 0) {
      const newImages = { ...images };
      let shouldUpdate = false;

      orderDetails.forEach((data) => {
        const productId = data?.product_id;
        if (!newImages[productId] && shopifyImages[productId]?.image) {
          newImages[productId] = shopifyImages[productId].image;
          shouldUpdate = true;
        }
      });

      if (shouldUpdate) {
        setImages(newImages);
      }
    }
  }, [orderDetails]);

  useEffect(() => {
    if (Object.keys(images).length > 0) {
      const updatedOrderDetails = orderDetails.map((item) => ({
        ...item,
        imgUrl: images[item?.product_id] || item.imgUrl,
      }));
      setOrderDetails(updatedOrderDetails);
    }
  }, [images]);

  return (
    <Box
      sx={{
        backgroundColor: `${trackingPortalConfig?.color_theme?.page_bg_color}`,
      }}
    >
      <ReturnsLayout isHeader>
        {isLoading && <CircularLoader />}

        <Box
          sx={{
            padding: 4,
            paddingTop: 0,
            width: "100%",
            zIndex: 1,
            overflowY: "auto",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              padding: "20px",
              backgroundColor: "#F2F2F2",
              borderRadius: "10px",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box sx={{ padding: "0 20px 0 0" }}>
                <Typography>
                  Order ID: {returnDetails?.return_id || 11140137053}
                </Typography>
              </Box>
              <Typography
                sx={{
                  borderLeft: "2px solid #D9D9D9",
                  borderRight: "2px solid #D9D9D9",
                  padding: "0 20px",
                }}
              >
                Return initiated date: {returnInitiated}
              </Typography>
              <Typography sx={{ padding: "0 20px" }}>
                Current status:{" "}
                {returnDetails?.return_status || "Return Initiated"}
              </Typography>
            </Box>
            {!trackingId && (
              <LoadingButton
                sx={{
                  backgroundColor: `${trackingPortalConfig?.color_theme?.brand_color}`,
                  color: "#fff",
                  "&:hover": {
                    backgroundColor: `${trackingPortalConfig?.color_theme?.brand_color}`,
                  },
                }}
              >
                Cancel return request
              </LoadingButton>
            )}
          </Box>
          <Grid container spacing={4}>
            <Grid item xs={12} lg={8}>
              <Typography
                sx={{
                  padding: "5px 20px",
                  borderRadius: "10px",
                  backgroundColor: `${trackingPortalConfig?.color_theme?.brand_color}`,
                  color: "white",
                  mt: 4,
                }}
              >
                Return order status
              </Typography>
              <Box sx={{ width: "100%", my: 3 }}>
                <Stepper alternativeLabel>
                  {returnStatusHistory.map((label, index) => (
                    <Step key={index} sx={{ position: "relative" }}>
                      <StepLabel
                        StepIconComponent={() => (
                          <Box
                            sx={{
                              width: "30px",
                              color: "white",
                              backgroundColor: `${
                                label.status === "Rejected" ||
                                label.status === "Canceled"
                                  ? "#EA1919"
                                  : "#4DAC20"
                              }`,
                              height: "30px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              borderRadius: "50%",
                            }}
                          >
                            {label.status === "Initiated" && (
                              <KeyboardReturnIcon
                                sx={{ color: "white" }}
                                fontSize="10px"
                              />
                            )}
                            {label.status === "Accepted" && (
                              <AssignmentTurnedInIcon
                                sx={{ color: "white" }}
                                fontSize="10px"
                              />
                            )}
                            {label.status === "Refunded" && (
                              <CreditScoreIcon
                                sx={{ color: "white" }}
                                fontSize="10px"
                              />
                            )}
                            {label.status === "Rejected" && (
                              <CreditScoreIcon
                                sx={{ color: "white" }}
                                fontSize="10px"
                              />
                            )}
                            {label.status === "Canceled" && (
                              <CreditScoreIcon
                                sx={{ color: "white" }}
                                fontSize="10px"
                              />
                            )}
                          </Box>
                        )}
                        sx={{
                          textAlign: "center",
                        }}
                      >
                        {label.status}
                      </StepLabel>
                      <Typography
                        variant="caption"
                        sx={{
                          display: "block",
                          color: "#AAAAAA",
                          textAlign: "center",
                          mt: 1,
                        }}
                      >
                        {unixToDate(label.created_at)}
                      </Typography>
                    </Step>
                  ))}
                  {statuses[currentStatus]?.map((label, index) => (
                    <Step key={index} sx={{ position: "relative" }}>
                      <StepLabel
                        StepIconComponent={() => (
                          <Box
                            sx={{
                              width: "30px",
                              color: "white",
                              backgroundColor: "#AAAAAA",
                              height: "30px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              borderRadius: "50%",
                            }}
                          >
                            {label.icon}
                          </Box>
                        )}
                        sx={{
                          textAlign: "center",
                        }}
                      >
                        {label.name}
                      </StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </Box>
              <Typography
                sx={{
                  padding: "5px 20px",
                  borderRadius: "10px",
                  backgroundColor: `${trackingPortalConfig?.color_theme?.brand_color}`,
                  color: "white",
                  mt: 4,
                }}
              >
                Return items
              </Typography>
              <Grid container spacing={3} sx={{ zIndex: 1 }}>
                <Box
                  sx={{
                    height: "150px",
                    ml: "24px",
                    mr: "24px",
                    overflowY: "auto",
                    mt: "26px",
                    minWidth: "800px",
                  }}
                >
                  {orderDetails?.map((item, index) => {
                    console.log(item, "item");
                    return (
                      <>
                        <Box
                          key={index}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            width: "100%",
                            padding: "20px 0 0 20px",
                          }}
                        >
                          <img
                            width={125}
                            height={125}
                            style={{
                              objectFit: "contain",
                              border: "1px solid #E4E4E4",
                              borderRadius: "10px",
                            }}
                            src={item.imgUrl}
                          />
                          <Box sx={{ width: "100%", pl: 2 }}>
                            <Grid container spacing={1}>
                              <Grid item xs={12} sx={{ marginY: "auto" }}>
                                <Box
                                  sx={{
                                    display: "flex",
                                    gap: 1,
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <Box>
                                    <Typography
                                      sx={{
                                        color: "#000",
                                        fontSize: "14px",
                                        mb: 1,
                                        padding: "5px 10px",
                                        borderRadius: "20px",
                                        width: "max-content",
                                        backgroundColor: "#E7E7E7",
                                      }}
                                    >
                                      Reason for Refund : {item.return_reason}
                                    </Typography>
                                    <Typography
                                      sx={{
                                        color: "#000",
                                        fontSize: "14px",
                                        mb: 1,
                                      }}
                                    >
                                      {item.name}
                                    </Typography>
                                    <Typography
                                      sx={{
                                        color: "#0009",
                                        fontSize: "13px",
                                      }}
                                    >
                                      SKU{" "}
                                      <span style={{ color: "#000" }}>
                                        {item.sku || "-"}
                                      </span>
                                    </Typography>
                                  </Box>
                                  <Box>
                                    <Typography
                                      sx={{
                                        color: "#000",
                                        fontSize: "14px",
                                        mb: 1,
                                      }}
                                    >
                                      {currency_symbol} {item.price}
                                    </Typography>
                                    <Typography
                                      sx={{
                                        color: "#0009",
                                        fontSize: "13px",
                                        textAlign: "right",
                                      }}
                                    >
                                      Qty{" "}
                                      <span style={{ color: "#000" }}>
                                        {item.quantity}
                                      </span>
                                    </Typography>
                                  </Box>
                                </Box>
                              </Grid>
                            </Grid>
                          </Box>
                        </Box>
                      </>
                    );
                  })}
                </Box>
              </Grid>
            </Grid>
            <RefundEstimateInfo
              currency_symbol={currency_symbol}
              returnEstimate={returnEstimate}
              trackingItems={orderDetails}
            />
          </Grid>
        </Box>
      </ReturnsLayout>
    </Box>
  );
};

export default Preview;
