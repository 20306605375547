import {
  Box,
  Button,
  Dialog,
  Grid,
  IconButton,
  Stack,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import ReturnsLayout from "./ReturnsLayout";
import { LoadingButton } from "@mui/lab";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import WarehouseIcon from "@mui/icons-material/Warehouse";
import CreditScoreIcon from "@mui/icons-material/CreditScore";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import useCustomerOrderInfo from "../../../features/return_management/hooks/useCustomerOrderInfo";
import TrackingPageItemCard from "../components/TrackingPageItemCard";
import TrackingItemCard from "../components/TrackingItemCard";
import {
  convertUTCtoDateFormat,
  unixToDate,
  getCurrencySymbol,
} from "../../../utils/core";
import RefundEstimateInfo from "../components/RefundEstimateInfo";
import {
  CancelReturn,
  getOrderStatus,
  getReturnDetails,
} from "src/pages/returns/ReturnsApi";
import CircularLoader from "src/loader/circular/CircularLoader";
import { useSelector } from "react-redux";
import { useAppSelector } from "src/hooks/hooks";
import {
  LABEL_PROCESS_TYPE,
  LABELS,
} from "src/pages/ReturnConfiguration/ReturnMethod/Labels";
import { getLabel } from "src/features/returnTracking/returnTrackingApis";
import { getUserInfo } from "src/features/user/userState";
import BarSettingsIcon from "src/components/Icons/BarSettings";
import { getTrackingInfoFromCarrier, PostPortalLogin } from "../PortalApi";
import { retrieveLogoUrl } from "../../../features/returnConfiguration/emailTemplatesApi";

const ReturnsTracking = ({ isHeader = true }) => {
  const [returnStatusHistory, setReturnStatusHistory] = useState([]);
  const [currentStatus, setCurrentStatus] = useState("");
  const [isModalOpen, setModalOpen] = useState(false);
  const [modalText, setModalText] = useState("");
  const [isCustomLoading, setIsCustomLoading] = useState(false);
  const [isSuccessModalOpen, setSuccessModalOpen] = useState(false);
  const [successModalText, setSuccessModalText] = useState("");
  const navigate = useNavigate();
  const { tenant_name } = useParams();
  const [trackingItems, setTrackingItems] = useState([]);
  const [isViewLabelModal, setIsViewLabelModal] = useState(false);
  const [refundSummary, setRefundSummary] = useState(null);

  const {
    customerOrderInfo,
    returnEstimate,
    returnInfo,
    tenantInfo,
    customAuthInfo,
    isLoading,
    trackingPortalConfig,
    productImages,
    fetchCustomerOrderInfo,
    fetchEstimateReturnSummary,
    fetchReturnInformationById,
    customerLoginSuccess,
    updateCustomerReturnInfo,
  } = useCustomerOrderInfo();
  const selectedReturnMethod = useAppSelector(
    (state) => state.returnMethod.selectedMethod
  );
  const currency_symbol = getCurrencySymbol(customerOrderInfo.currency);
  let return_base_path =
    customAuthInfo.return_flow === "customer_support"
      ? "returns_support"
      : "returns";
  const params = new URLSearchParams(location.search);
  const trackingId = params.get("tracking_id");

  useEffect(() => {
    if (tenantInfo?.tenant_id && trackingId != "" && trackingId != null) {
      if (trackingId) {
        const fetchAllData = async () => {
          const response = await getReturnDetails(trackingId, tenantInfo);
          if (response?.data?._id) {
            const return_info = response.data;
            const login_payload = {
              order_number: return_info.order_number,
              email_id: return_info.email,
            };
            const loginResponse = await PostPortalLogin(
              tenantInfo,
              login_payload
            );
            const customer_info = loginResponse.data;
            let otp_payload = {
              tenant_id: tenantInfo.tenant_id,
              action: "customer_return_flow",
              customer_name:
                customer_info.first_name + " " + customer_info.last_name,
              email_id: login_payload.email_id,
            };
            updateCustomerReturnInfo(return_info);
            const payload = {
              user_info: customer_info,
              return_flow: "guest_user",
              tenant_name: tenant_name,
            };
            customerLoginSuccess(payload);
          }
        };
        fetchAllData();
      }
      // resetBrandPortalInfo("");
    }
  }, [trackingId, tenantInfo?.tenant_id]);

  useEffect(() => {
    if (
      customAuthInfo?.isLoggedIn &&
      customAuthInfo?.user_info?.order_id &&
      trackingId != null &&
      trackingId != ""
    ) {
      if (returnInfo?._id) {
        fetchCustomerOrderInfo(tenantInfo, {
          order_number: customAuthInfo?.user_info.order_id,
        });
        fetchReturnInformationById(tenantInfo, {
          return_id: returnInfo?._id,
        });
      }
    }
  }, [trackingId, customAuthInfo?.isLoggedIn]);

  useEffect(() => {
    if (trackingId == "" && trackingId == null) {
      if (customAuthInfo?.isLoggedIn && customAuthInfo?.user_info?.order_id) {
        if (returnInfo?._id) {
          fetchCustomerOrderInfo(tenantInfo, {
            order_number: customAuthInfo?.user_info.order_id,
          });
          fetchReturnInformationById(tenantInfo, {
            return_id: returnInfo?._id,
          });
        } else {
          if (customAuthInfo.return_flow === "customer_support") {
            navigate(`/returns_support/${tenant_name}/create-return`);
          } else {
            navigate(`/returns/${tenant_name}/create-return`);
          }
        }
      } else {
        navigate(`/returns/${tenant_name}`);
      }
    }
  }, []);

  const groupBy = (array, key) => {
    return array.reduce((result, currentValue) => {
      // Get the value of the key we want to group by
      const groupKey = currentValue[key];
      // If the key doesn't exist in the result object, create it and assign an empty array
      if (!result[groupKey]) {
        result[groupKey] = [];
      }
      // Push the current value to the group
      result[groupKey].push(currentValue);
      // Return the result object for the next iteration
      return result;
    }, {}); // Initial value of the result object is an empty object
  };
  useEffect(() => {
    if (customerOrderInfo?.order_id) {
      const order_line_items = customerOrderInfo.line_items;
      const groupData = groupBy(order_line_items, "id");

      const returnOrderList = returnInfo.line_items.map((item) => {
        const lineItem = groupData[item.line_item_id]?.[0];
        const product_id = lineItem?.product_id;

        let image = "/assets/no-image.png";
        if (typeof productImages[product_id] != "undefined") {
          const product_img = productImages[product_id]?.image;
          if (product_img != "" && product_img != null) {
            image = product_img;
          }
        }

        return {
          id: lineItem.id,
          title: lineItem.name,
          description: lineItem.title,
          price: lineItem.price,
          imgUrl: image,
          ...item,
        };
      });
      setTrackingItems(returnOrderList);
    }
  }, []);
  useEffect(() => {
    if (customerOrderInfo?.order_id) {
      const order_line_items = customerOrderInfo.line_items;
      const groupData = groupBy(order_line_items, "id");
      let return_line_items = [];
      const returnOrderList = returnInfo.line_items.map((item) => {
        const lineItem = groupData[item.line_item_id][0];
        return_line_items.push({
          line_item_id: item.return_line_item_id,
          quantity: item.quantity,
        });
        const product_id = lineItem?.product_id;
        let image = "/assets/no-image.png";
        if (typeof productImages[product_id] != "undefined") {
          const product_img = productImages[product_id]?.image;
          if (product_img !== "" && product_img != null) {
            image = product_img;
          }
        }
        return {
          id: lineItem.id,
          title: lineItem.name,
          description: lineItem.title,
          price: lineItem.price,
          imgUrl: image,
          ...item,
        };
      });
      setTrackingItems(returnOrderList);

      let payload = {
        order_id: returnInfo.order_id,
        return_id: returnInfo.return_id,
        refund_shipping: {
          amount: "0",
          currencyCode: returnInfo.currency,
        },
        line_items: return_line_items,
      };
      if (returnInfo.return_status == "Refunded") {
        const refund_summary = returnInfo?.refund_summary;
        const summary = {
          return_items_amount: refund_summary.items_subtotal,
          currency: returnInfo.return_summary.currency,
          order_discount: refund_summary.order_discount,
          tax_amount: refund_summary.tax_amount,
          shipping_maximum_refundable: refund_summary.shipping_charges,
          total_refund: refund_summary.grand_total,
          adjustment_amount: refund_summary.adjustment_amount,
          label_fees: refund_summary.label_fees,
          deduction: refund_summary.deduction,
        };
        setRefundSummary(summary);
      } else {
        fetchEstimateReturnSummary(tenantInfo, payload);
      }
    }
  }, [customerOrderInfo?.order_id]);

  const userInfo = useAppSelector(getUserInfo);

  const handleCancel = () => {
    setModalOpen(true);
    setModalText("Are you sure you want to cancel this return ?");
  };

  const handleViewLabel = async () => {
    setIsViewLabelModal(true);
  };

  let timelineStatus = {
    Initiated: [
      {
        name: "Accepted",
        icon: (
          <AssignmentTurnedInIcon fontSize="10px" sx={{ color: "#D6D6D6" }} />
        ),
      },
      {
        name: "Refunded",
        icon: <CreditScoreIcon fontSize="10px" sx={{ color: "#D6D6D6" }} />,
      },
    ],
    Accepted: [
      {
        name: "Refunded",
        icon: <CreditScoreIcon fontSize="10px" sx={{ color: "#D6D6D6" }} />,
      },
    ],
  };

  const addTrackingStatus = (statuses, returnInfo) => {
    if (returnInfo?.label_type === LABEL_PROCESS_TYPE.OWN_LABEL) {
      const trackingStatus = {
        name: "Tracking number updated",
        icon: <BarSettingsIcon fontSize="10px" sx={{ color: "#D6D6D6" }} />,
      };

      // Insert tracking status as second item for Initiated and Accepted if missing
      ["Initiated", "Accepted"].forEach((statusKey) => {
        if (
          !statuses[statusKey].some(
            (status) => status.name === "Tracking number updated"
          )
        ) {
          statuses[statusKey].splice(1, 0, trackingStatus);
        }
      });

      if (!statuses["Tracking number updated"]) {
        statuses["Tracking number updated"] = [
          {
            name: "Refunded",
            icon: <CreditScoreIcon fontSize="10px" sx={{ color: "#D6D6D6" }} />,
          },
        ];
      }
    }
    return statuses;
  };

  // Call the function with current statuses
  let statuses = addTrackingStatus(timelineStatus, returnInfo);

  function getOrderStatusData() {
    getOrderStatus(returnInfo._id, tenantInfo)
      .then((res) => {
        let data = res.data;
        if (
          returnInfo.label_type === LABEL_PROCESS_TYPE.OWN_LABEL &&
          returnInfo?.label_info?.tracking_number !== "" &&
          returnInfo?.label_info?.tracking_number != null
        ) {
          const label_info = returnInfo.label_info;
          const acceptedIndex = data.findIndex(
            (item) => item.status === "Accepted"
          );
          if (acceptedIndex !== -1) {
            const newElement = {
              created_at: label_info?.updated_at, // UNIX timestamp
              created_by: label_info.updated_source,
              label_type: "own_label",
              return_list_id: label_info.return_id,
              status: "Tracking number updated",
              tenant_id: returnInfo.tenant_id,
            };
            data.splice(acceptedIndex + 1, 0, newElement);
          }
        }
        setReturnStatusHistory(data);
        setCurrentStatus(data[res?.data?.length - 1]?.status);
      })
      .catch((e) => console.log(e, "error"));
  }

  useEffect(() => {
    if (returnInfo?._id) {
      getOrderStatusData();
    }
  }, [returnInfo?._id]);

  const handleSubmit = () => {
    setModalOpen(false);
    setIsCustomLoading(true);
    CancelReturn({ return_id: returnInfo._id, reason: "OTHER" }, tenantInfo)
      .then((res) => {
        setIsCustomLoading(false);
        setSuccessModalOpen(true);
        setSuccessModalText("Successfully Canceled!");
        getOrderStatusData();
        fetchReturnInformationById(tenantInfo, {
          return_id: returnInfo?._id,
        });
      })
      .catch((e) => {
        setIsCustomLoading(false);
        setSuccessModalOpen(true);
        setSuccessModalText(e.response.data.statusMessage);
      });
  };

  async function handleViewLabelDownload() {
    try {
      // Fetch the image from the S3 URL
      const labelUrlPreview = `${retrieveLogoUrl}/${returnInfo?.label_info?.label_path}`;
      const response = await fetch(labelUrlPreview);

      // Check if the response is OK
      if (!response.ok) {
        throw new Error("Network response was not ok " + response.statusText);
      }

      // Convert the response to a Blob
      const blob = await response.blob();

      const fileName = labelUrlPreview.split("/").pop();

      // Create a link element
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = fileName; // Set the desired file name

      // Append to the body (required for Firefox)
      document.body.appendChild(link);

      // Programmatically click the link to trigger the download
      link.click();

      // Clean up: remove the link and revoke the Blob URL
      document.body.removeChild(link);
      window.URL.revokeObjectURL(link.href);
    } catch (error) {
      console.error("Error downloading image:", error);
    }
  }

  function getCurrentStatus(status) {
    if (status == "Accepted") {
      if (
        returnInfo.label_type === LABEL_PROCESS_TYPE.OWN_LABEL &&
        returnInfo?.label_info?.tracking_number !== "" &&
        returnInfo?.label_info?.tracking_number != null
      ) {
        return "Tracking number updated";
      }
    }
    return status;
  }

  return (
    <Box
      sx={{
        backgroundColor: `${trackingPortalConfig?.color_theme?.page_bg_color}`,
      }}
    >
      <ReturnsLayout isHeader={isHeader}>
        {(isLoading || isCustomLoading) && <CircularLoader />}

        <Box
          sx={{
            padding: 4,
            paddingTop: 0,
            width: "100%",
            zIndex: 1,
            overflowY: "auto",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              padding: "20px",
              backgroundColor: "#F2F2F2",
              borderRadius: "10px",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box sx={{ padding: "0 20px 0 0" }}>
                <Typography>Return ID: {returnInfo?.return_id}</Typography>
              </Box>
              <Typography
                sx={{
                  borderLeft: "2px solid #D9D9D9",
                  borderRight: "2px solid #D9D9D9",
                  padding: "0 20px",
                }}
              >
                Return initiated date:{" "}
                {convertUTCtoDateFormat(returnInfo?.created_at)}
              </Typography>
              <Typography sx={{ padding: "0 20px" }}>
                Current status: {returnInfo?.return_status}
              </Typography>
            </Box>
            <Stack direction={"row"} gap={2}>
              {(returnInfo?.label_type ===
                LABEL_PROCESS_TYPE.PREPAID_FREE_LABEL ||
                returnInfo?.label_type ===
                  LABEL_PROCESS_TYPE.PREPAID_PAID_LABEL) && (
                <LoadingButton
                  sx={{
                    backgroundColor: `${trackingPortalConfig?.color_theme?.brand_color}`,
                    color: "#fff",
                    paddingX: 2,
                    "&:hover": {
                      backgroundColor: `${trackingPortalConfig?.color_theme?.brand_color}`,
                    },
                  }}
                  onClick={handleViewLabel}
                >
                  View Label
                </LoadingButton>
              )}
              {returnInfo.return_status === "Initiated" && (
                <LoadingButton
                  sx={{
                    backgroundColor: `${trackingPortalConfig?.color_theme?.brand_color}`,
                    color: "#fff",
                    paddingX: 2,
                    "&:hover": {
                      backgroundColor: `${trackingPortalConfig?.color_theme?.brand_color}`,
                    },
                  }}
                  onClick={handleCancel}
                >
                  Cancel return request
                </LoadingButton>
              )}
            </Stack>
          </Box>
          <Grid container spacing={4}>
            <Grid item xs={12} lg={8}>
              <Typography
                sx={{
                  padding: "5px 20px",
                  borderRadius: "10px",
                  backgroundColor: `${trackingPortalConfig?.color_theme?.brand_color}`,
                  color: "white",
                  mt: 4,
                }}
              >
                Return order status
              </Typography>
              <Box sx={{ width: "100%", my: 3 }}>
                <Stepper alternativeLabel>
                  {returnStatusHistory.map((label, index) => (
                    <Step key={index} sx={{ position: "relative" }}>
                      <StepLabel
                        StepIconComponent={() => (
                          <Box
                            sx={{
                              width: "30px",
                              color: "white",
                              backgroundColor: `${
                                label.status === "Rejected" ||
                                label.status === "Canceled"
                                  ? "#EA1919"
                                  : "#4DAC20"
                              }`,
                              height: "30px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              borderRadius: "50%",
                            }}
                          >
                            {label.status === "Initiated" && (
                              <KeyboardReturnIcon
                                sx={{ color: "white" }}
                                fontSize="10px"
                              />
                            )}
                            {label.status === "Accepted" && (
                              <AssignmentTurnedInIcon
                                sx={{ color: "white" }}
                                fontSize="10px"
                              />
                            )}
                            {label.status === "Tracking number updated" && (
                              <BarSettingsIcon color="white" fontSize="10px" />
                            )}

                            {label.status === "Refunded" && (
                              <CreditScoreIcon
                                sx={{ color: "white" }}
                                fontSize="10px"
                              />
                            )}
                            {label.status === "Rejected" && (
                              <CreditScoreIcon
                                sx={{ color: "white" }}
                                fontSize="10px"
                              />
                            )}
                            {label.status === "Canceled" && (
                              <CreditScoreIcon
                                sx={{ color: "white" }}
                                fontSize="10px"
                              />
                            )}
                          </Box>
                        )}
                        sx={{
                          textAlign: "center",
                        }}
                      >
                        {label.status}
                      </StepLabel>
                      <Typography
                        variant="caption"
                        sx={{
                          display: "block",
                          color: "#AAAAAA",
                          textAlign: "center",
                          mt: 1,
                        }}
                      >
                        {unixToDate(label.created_at)}
                      </Typography>
                    </Step>
                  ))}
                  {statuses[getCurrentStatus(returnInfo?.return_status)]?.map(
                    (label, index) => (
                      <Step key={index} sx={{ position: "relative" }}>
                        <StepLabel
                          StepIconComponent={() => (
                            <Box
                              sx={{
                                width: "30px",
                                color: "white",
                                backgroundColor: "#AAAAAA",
                                height: "30px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                borderRadius: "50%",
                              }}
                            >
                              {label.icon}
                            </Box>
                          )}
                          sx={{
                            textAlign: "center",
                          }}
                        >
                          {label.name}
                        </StepLabel>
                      </Step>
                    )
                  )}
                </Stepper>
              </Box>
              <Typography
                sx={{
                  padding: "5px 20px",
                  borderRadius: "10px",
                  backgroundColor: `${trackingPortalConfig?.color_theme?.brand_color}`,
                  color: "white",
                  mt: 4,
                }}
              >
                Return items
              </Typography>
              <Box
                sx={{
                  height: "150px",
                  ml: "24px",
                  mr: "24px",
                  overflowY: "auto",
                  mt: "26px",
                  minWidth: "800px",
                }}
              >
                {trackingItems.map((data) => (
                  <TrackingItemCard
                    themeConfig={{ trackingPortalConfig }}
                    currency_symbol={currency_symbol}
                    data={data}
                    returnInfo={returnInfo}
                  />
                ))}
              </Box>
            </Grid>
            <RefundEstimateInfo
              currency_symbol={currency_symbol}
              returnEstimate={
                refundSummary == null ? returnEstimate : refundSummary
              }
              trackingItems={trackingItems}
            />
          </Grid>
        </Box>
        <Dialog
          fullWidth={true}
          open={isModalOpen}
          onClose={() => setModalOpen(false)}
          maxWidth="xs"
        >
          <Box sx={{ padding: "20px" }}>
            <Typography
              sx={{ color: "#000", textAlign: "center", fontSize: "16px" }}
            >
              {modalText}
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 2,
                justifyContent: "center",
              }}
            >
              <Button
                variant="outlined"
                sx={{
                  display: "block",
                  width: "130px",
                  boxShadow: "none",
                  mt: 2,
                }}
                onClick={() => setModalOpen(false)}
              >
                No
              </Button>
              <LoadingButton
                variant="contained"
                sx={{
                  display: "block",
                  width: "130px",
                  backgroundColor: `${trackingPortalConfig?.color_theme?.brand_color}`,
                  boxShadow: "none",
                  mt: 2,
                }}
                onClick={handleSubmit}
              >
                Yes
              </LoadingButton>
            </Box>
          </Box>
        </Dialog>
        {isLoading ? (
          <CircularLoader />
        ) : (
          <Dialog
            fullWidth={true}
            open={isSuccessModalOpen}
            onClose={() => setSuccessModalOpen(false)}
            maxWidth="xs"
          >
            <Box sx={{ padding: "20px" }}>
              <Typography
                sx={{ color: "#000", textAlign: "center", fontSize: "14px" }}
              >
                {successModalText}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  mb: 2,
                  mt: 3,
                }}
              >
                <LoadingButton
                  variant="contained"
                  sx={{
                    width: "130px",
                    backgroundColor: `${trackingPortalConfig?.color_theme?.brand_color}`,
                  }}
                  onClick={() => setSuccessModalOpen(false)}
                >
                  Close
                </LoadingButton>
              </Box>
            </Box>
          </Dialog>
        )}
      </ReturnsLayout>
      {(returnInfo.label_type === LABEL_PROCESS_TYPE.PREPAID_PAID_LABEL ||
        returnInfo.label_type == LABEL_PROCESS_TYPE.PREPAID_FREE_LABEL) && (
        <Dialog
          // fullWidth={true}
          open={isViewLabelModal}
          onClose={() => setIsViewLabelModal(false)}
          maxWidth="xs"
          // sx={{padding:"30px"}}
        >
          <Box
            textAlign={"right"}
            px={2}
            pt={1}
            sx={{ cursor: "pointer" }}
            onClick={() => setIsViewLabelModal(false)}
          >
            X
          </Box>
          <>
            <Box
              display={"flex"}
              justifyContent={"center"}
              sx={{ paddingX: "50px" }}
            >
              <img
                src={`${retrieveLogoUrl}${returnInfo?.label_info?.label_path}`}
                width={"330px"}
                height={"450px"}
              />
            </Box>
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              sx={{ padding: "20px" }}
            >
              <Button
                sx={{
                  display: "flex",
                  alignItems: "center",
                  fontSize: "16px",
                  textTransform: "none",
                  fontWeight: 500,
                  borderColor: "secondary.main",
                  color: "secondary.main",
                }}
                onClick={handleViewLabelDownload}
                startIcon={<img src="/assets/icons/print_label.svg" />}
              >
                <p> Print or download</p>
              </Button>
            </Box>
          </>
        </Dialog>
      )}
    </Box>
  );
};

export default ReturnsTracking;
