import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import typography from "src/theme/typography";
import useCustomerOrderInfo from "../../../features/return_management/hooks/useCustomerOrderInfo";
import { useEffect, useState } from "react";
import PolicyIcon from "./component/PolicyIcon";
import CloseIcon from "@mui/icons-material/Close";
import MailIcon from "./component/MailIcon";
import {
  getBrandPortalInfo,
  getTrackingPortalInfo,
} from "../../../features/return_management/order/customerBrandPortalApi";
import { useLocation, useParams } from "react-router-dom";
import { retrieveLogoUrl } from "src/features/config/configApi";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import { setFavicon } from "../../../utils/core";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { useSelector } from "react-redux";
import SupportIcon from "./component/SupportIcon";
import InventoryIcon from "./component/InventoryIcon";
import Page404 from "src/pages/Page404";

const ReturnsLayout = ({
  isHeader = false,
  isReturnHeadColor = false,
  children = <div></div>,
  isCx = false,
}) => {
  const {
    customAuthInfo,
    brandPortalConfig,
    trackingPortalConfig,
    productImages,
    tenantInfo,
    error,
    fetchProductImages,
    updateBrandPortalInfo,
    updateTrackingPortalInfo,
    fetchTenantInfo,
    customerOrderInfo,
  } = useCustomerOrderInfo();
  const [portalConfig, setPortalConfig] = useState({});
  const [isTrackingPage, setIsTrackingPage] = useState(false);
  const [isPolicyModalOpen, setPolicyModalOpen] = useState(false);
  let { tenant_name } = useParams();
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };
  const location = useLocation();
  const shopifyImage = useSelector(
    (state) => state.shopifyImages.shopifyImages
  );
  const pathSegments = location.pathname
    .split("/")
    .filter((segment) => segment);
  const lastSegment = pathSegments[pathSegments.length - 1];
  const query = useQuery();
  const paramValue = query.get("preview");
  const isPreview = paramValue != null;
  const portal_config = {
    color_theme: {
      brand_color: "#256fa5",
      secondary_color: "#6f6b6b",
      page_bg_color: "inherit",
    },
    corner_radius: "",
    customer_support: {
      email_id: "",
      phone_no: "",
    },
    favicon: "",
    heading: "Return Management",
    logo: "",
    logo_url: "",
    background_image: "",
    return_policy: {
      is_custom: false,
      link_url: "",
      link_text: "Return Policy",
      custom_content: null,
    },
  };
  const tracking_portal_config = {
    logo: "",
    logo_url: "",
    background_image: "",
    favicon: "",
    color_theme: {
      brand_color: "#256fa5",
      secondary_color: "",
      page_bg_color: "#ff",
    },
    corner_radius: "",
    tracking_analytics: "",
    header: {
      header_text: "Tracking Summary",
      text_color: "#256fa5",
      background_color: "",
    },
    footer: {
      custom_content: false,
      privacy_url: "Privacy Policy",
      terms_url: "Terms & Conditions",
      link_text: "",
      policy_content: "",
      text_color: "#fff",
      background_color: "#256fa5",
    },
    customer_support: {
      email_id: "",
      phone_no: "",
    },
    social_media: {
      facebook: "",
      instagram: "",
    },
    shipping_info: "",
    website_url: "",
  };

  async function updateTrackingPortal() {
    try {
      const response = await getTrackingPortalInfo(tenantInfo, {});
      const data = response.data;
      if (data?.color_theme) {
        const config = {
          color_theme: data.color_theme,
          corner_radius: data?.corner_radius,
          customer_support: data?.customer_support,
          favicon: data?.favicon,
          footer: data?.footer,
          header: data?.header,
          logo: data?.logo,
          logo_url: data?.logo_url,
          background_image: data?.background_image,
          social_media: data?.social_media,
          tracking_analytics: data?.tracking_analytics,
          website_url: data?.website_url,
        };
        await updateTrackingPortalInfo(config);
      } else {
        await updateTrackingPortalInfo(tracking_portal_config);
      }
    } catch (e) {
      await updateTrackingPortalInfo(tracking_portal_config);
    }
  }

  async function updateBrandPortal() {
    try {
      const response = await getBrandPortalInfo(tenantInfo, {});
      const data = response.data;
      if (data?.logo) {
        const config = {
          logo: data.logo,
          logo_url: data.logo_url,
          background_image: data.background_image,
          color_theme: data?.color_theme,
          faq: data.faq,
          favicon: data.favicon,
          corner_radius: data.corner_radius,
          heading: data.heading,
          return_policy: data.return_policy,
          shipping_info: data.shipping_info,
          customer_support: data.customer_support,
          website_url: data.website_url,
          social_media: data.social_media,
        };
        updateBrandPortalInfo(config);
      } else {
        updateBrandPortalInfo(portal_config);
      }
    } catch (e) {
      updateBrandPortalInfo(portal_config);
    }
  }

  useEffect(() => {
    if (!isPreview) {
      if (paramValue === null) {
        const fetchData = async () => {
          updateTrackingPortal();
          updateBrandPortal();
        };
        fetchData();
      }
      if (Object.keys(shopifyImage).length <= 0) {
        fetchProductImages(tenantInfo);
      }
      if (customAuthInfo.isLoggedIn) {
        tenant_name = tenantInfo.tenant_name;
      }
      fetchTenantInfo(tenantInfo, { tenant_name: tenant_name });
    }
  }, [tenantInfo?.tenant_id]);

  useEffect(() => {
    let config = {
      color_theme: brandPortalConfig?.color_theme,
      favicon: brandPortalConfig?.favicon,
      header: {
        header_text: "BeyondCarts Store",
        text_color: brandPortalConfig?.color_theme?.brand_color,
        background_color: brandPortalConfig?.color_theme?.page_bg_color,
      },
      footer: {
        custom_content: false,
        privacy_url: "https://beyondcarts.com/",
        terms_url: "",
        link_text: "",
        policy_content: "",
        text_color: "#fff",
        background_color: brandPortalConfig?.color_theme?.brand_color,
      },
    };
    if (lastSegment === "tracking" || lastSegment === "demo") {
      config = {
        color_theme: trackingPortalConfig?.color_theme,
        favicon: trackingPortalConfig?.favicon,
        header: trackingPortalConfig.header,
        footer: trackingPortalConfig.footer,
      };
    }
    if (config?.favicon && config.favicon != null && config.favicon != "") {
      setFavicon(retrieveLogoUrl + config?.favicon);
    }
    setPortalConfig(config);
  }, [trackingPortalConfig, brandPortalConfig]);

  useEffect(() => {
    // console.log('paramValue ', paramValue)
    // if (paramValue === null) {
    //   const fetchData = async () => {
    //     updateTrackingPortal()
    //     updateBrandPortal()
    //   };
    //   fetchData();
    // }
    // if (Object.keys(productImages).length <= 0) {
    //   fetchProductImages(tenantInfo);
    // }
    // if (customAuthInfo.isLoggedIn) {
    //   tenant_name = tenantInfo.tenant_name;
    // }
    fetchTenantInfo(tenantInfo, { tenant_name: tenant_name });
  }, [tenantInfo?.tenant_id]);

  useEffect(() => {
    if (!tenantInfo?.tenant_id || tenant_name != tenantInfo?.tenant_name) {
      fetchTenantInfo(tenantInfo, { tenant_name: tenant_name });
    }
    setIsTrackingPage(lastSegment === "tracking" || lastSegment === "demo");
  }, []);

  const header_phoneno = isTrackingPage
    ? trackingPortalConfig?.customer_support?.phone_no
    : customAuthInfo?.user_info?.phone_number;
  const header_email_id = isTrackingPage
    ? trackingPortalConfig?.customer_support?.email_id
    : customAuthInfo?.user_info?.email;
  const header_text = isTrackingPage
    ? trackingPortalConfig?.header?.header_text
    : brandPortalConfig?.header?.header_text;
  const header_logo = isTrackingPage
    ? trackingPortalConfig?.logo
    : brandPortalConfig?.logo;
  const header_logo_url = isTrackingPage
    ? trackingPortalConfig?.logo_url
    : brandPortalConfig?.logo_url;
  const backgroundImageUrl = isTrackingPage
    ? trackingPortalConfig?.background_image
    : brandPortalConfig?.background_image;
  const fullImageUrl = backgroundImageUrl
    ? `${retrieveLogoUrl}${backgroundImageUrl}`
    : "";

  return (
    <Stack
      alignItems={"center"}
      justifyContent={"space-between"}
      sx={{
        minHeight: isCx ? 0 : "100vh",
        width: "100%",
        position: "relative",
      }}
    >
      {fullImageUrl && (
        <img
          style={{ position: "absolute", height: "100%", width: "100%" }}
          src={fullImageUrl}
        />
      )}
      {/* header */}
      <Stack
        direction={"row"}
        // position={"sticky"}

        alignItems={"center"}
        justifyContent={"space-between"}
        paddingX={4}
        paddingY={3}
        width={"100%"}
        gap={0.6}
        zIndex={2}
        backgroundColor={
          isTrackingPage
            ? trackingPortalConfig?.header?.background_color
            : isReturnHeadColor
            ? trackingPortalConfig?.header?.background_color
            : "none"
        }
      >
        {isHeader && (
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
            width={"100%"}
          >
            {header_logo != "" && header_logo != null && (
              <a href={`${header_logo_url}`}>
                <img
                  src={retrieveLogoUrl + `${header_logo}`}
                  width={"auto"}
                  height={"50"}
                  alt="Brand Logo"
                />
              </a>
            )}
            {isTrackingPage && (
              <Typography
                fontFamily={typography.fontFamilySemiBold}
                fontSize={22}
                color={`${trackingPortalConfig?.header?.text_color}`}
              >
                {header_text}
              </Typography>
            )}
            {!isTrackingPage && (
              <Typography
                fontFamily={typography.fontFamilySemiBold}
                fontSize={22}
              >
                {header_text}
              </Typography>
            )}
            <Stack gap={3} direction={"row"}>
              {header_email_id != null && (
                <Stack
                  direction={"row"}
                  gap={1}
                  fontFamily={typography.fontFamilySemiBold}
                  alignItems={"center"}
                  justifyContent={"flex-start"}
                >
                  <MailIcon
                    color={`${trackingPortalConfig?.header?.text_color}`}
                  />
                  <a
                    href={`mailto:${brandPortalConfig?.customer_support?.email_id}`}
                    target="_blank"
                    style={{
                      color: `${trackingPortalConfig?.header?.text_color}`,
                    }}
                    rel="noopener noreferrer"
                  >
                    {header_email_id}
                  </a>
                </Stack>
              )}
              {isTrackingPage && header_phoneno != null && (
                <Stack
                  direction={"row"}
                  gap={1}
                  justifyContent={"flex-start"}
                  alignItems={"center"}
                >
                  <SupportIcon
                    color={`${trackingPortalConfig?.header?.text_color}`}
                  />
                  <a
                    style={{
                      color: `${trackingPortalConfig?.header?.text_color}`,
                    }}
                    href={`tel:${brandPortalConfig?.customer_support?.phone_no}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {header_phoneno}
                  </a>
                </Stack>
              )}
              {!isTrackingPage && customerOrderInfo?.order_number && (
                <Stack
                  direction={"row"}
                  gap={1}
                  justifyContent={"flex-start"}
                  alignItems={"center"}
                >
                  <InventoryIcon
                    color={`${trackingPortalConfig?.header?.text_color}`}
                  />
                  <Typography
                    sx={{
                      color: `${trackingPortalConfig?.header?.text_color}`,
                    }}
                  >
                    {customAuthInfo?.user_info?.order_number}
                  </Typography>
                </Stack>
              )}
            </Stack>
          </Stack>
        )}
      </Stack>

      {/* body */}
      {children}

      {/* footer */}
      {customAuthInfo?.return_flow != "customer_support" && (
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
          position={"sticky"}
          bottom={0}
          padding={2}
          sx={{
            width: "100%",
            borderTop: "1px solid #D7D7D7",
            backgroundColor: `${trackingPortalConfig?.footer?.background_color}`,
            zIndex: 1,
            border: "none",
            div: {
              flex: 1,
            },
          }}
        >
          <Stack direction={"row"} gap={1}></Stack>
          {((isTrackingPage &&
            (trackingPortalConfig?.footer?.terms_url ||
              trackingPortalConfig?.footer?.privacy_url)) ||
            lastSegment === "demo") && (
            <Stack
              direction={"row"}
              gap={1}
              color={portalConfig?.color_theme?.brand_color}
              fontFamily={typography.fontFamilySemiBold}
              justifyContent={"center"}
            >
              <PolicyIcon color={`${portalConfig?.footer?.text_color}`} />
              {trackingPortalConfig?.footer?.terms_url ? (
                <a
                  href={
                    trackingPortalConfig.footer?.terms_url &&
                    (trackingPortalConfig.footer?.terms_url.startsWith(
                      "http://"
                    ) ||
                      trackingPortalConfig.footer?.terms_url.startsWith(
                        "https://"
                      ))
                      ? trackingPortalConfig.footer?.terms_url
                      : `https://${trackingPortalConfig.footer?.terms_url}`
                  }
                  target="_blank"
                  style={{ color: portalConfig?.footer?.text_color }}
                >
                  Terms & Conditions&emsp;
                </a>
              ) : (
                <Typography sx={{ color: portalConfig?.footer?.text_color }}>
                  Terms & Conditions&emsp;
                </Typography>
              )}
              {trackingPortalConfig.footer?.privacy_url ? (
                <a
                  href={
                    trackingPortalConfig.footer?.privacy_url &&
                    (trackingPortalConfig.footer?.privacy_url.startsWith(
                      "http://"
                    ) ||
                      trackingPortalConfig.footer?.privacy_url.startsWith(
                        "https://"
                      ))
                      ? trackingPortalConfig.footer?.privacy_url
                      : `https://${trackingPortalConfig.footer?.privacy_url}`
                  }
                  target="_blank"
                  style={{ color: portalConfig?.footer?.text_color }}
                >
                  Privacy
                </a>
              ) : (
                <Typography sx={{ color: portalConfig?.footer?.text_color }}>
                  Privacy
                </Typography>
              )}
            </Stack>
          )}
          {!isTrackingPage && (
            <>
              <Stack
                direction={"row"}
                gap={1}
                alignItems={"center"}
                color={portalConfig?.color_theme?.brand_color}
                fontFamily={typography.fontFamilySemiBold}
                justifyContent={"center"}
              >
                {brandPortalConfig?.return_policy?.link_text && (
                  <>
                    <PolicyIcon color={`${portalConfig?.footer?.text_color}`} />
                    {brandPortalConfig?.return_policy?.is_custom ? (
                      <a
                        onClick={() => setPolicyModalOpen(true)}
                        // style={{ color: brandPortalConfig?.color_theme?.brand_color }}
                        style={{
                          color: portalConfig?.footer?.text_color,
                          cursor: "pointer",
                        }}
                      >
                        {brandPortalConfig?.return_policy?.link_text}
                      </a>
                    ) : !brandPortalConfig?.return_policy?.is_custom &&
                      brandPortalConfig?.return_policy?.link_url ? (
                      <a
                        href={
                          brandPortalConfig?.return_policy?.link_url &&
                          (brandPortalConfig?.return_policy?.link_url?.startsWith(
                            "http://"
                          ) ||
                            brandPortalConfig?.return_policy?.link_url.startsWith(
                              "https://"
                            ))
                            ? brandPortalConfig?.return_policy?.link_url
                            : `https://${brandPortalConfig?.return_policy?.link_url}`
                        }
                        target="_blank"
                        style={{
                          color: portalConfig?.footer?.text_color,
                          cursor: "pointer",
                        }}
                      >
                        {brandPortalConfig?.return_policy?.link_text}
                      </a>
                    ) : (
                      <Typography
                        sx={{ color: portalConfig?.footer?.text_color }}
                      >
                        {brandPortalConfig?.return_policy?.link_text}
                      </Typography>
                    )}{" "}
                  </>
                )}
              </Stack>
              <Stack alignItems={"flex-end"}>
                {brandPortalConfig?.customer_support?.email_id && (
                  <Box
                    sx={{
                      fontFamily: typography.fontFamilySemiBold,
                      alignItems: "center",
                      justifyContent: "center",
                      display: "flex",
                      gap: 2,
                    }}
                  >
                    <MailIcon color={`${portalConfig?.footer?.text_color}`} />
                    <a
                      href={`mailto:${brandPortalConfig?.customer_support?.email_id}`}
                      target="_blank"
                      style={{ color: portalConfig?.footer?.text_color }}
                    >
                      {brandPortalConfig?.customer_support?.email_id}
                    </a>
                  </Box>
                )}
                {brandPortalConfig?.customer_support?.phone_no
                  ?.split(" ")
                  .slice(1)
                  .join(" ") && (
                  <Box
                    sx={{
                      fontFamily: typography.fontFamilySemiBold,
                      alignItems: "center",
                      justifyContent: "center",
                      display: "flex",
                      gap: 2,
                    }}
                  >
                    <SupportIcon
                      color={`${portalConfig?.footer?.text_color}`}
                    />
                    <a
                      href={`tel:${brandPortalConfig?.customer_support?.phone_no}`}
                      target="_blank"
                      style={{ color: portalConfig?.footer?.text_color }}
                    >
                      {brandPortalConfig?.customer_support?.phone_no}
                    </a>
                  </Box>
                )}
              </Stack>
            </>
          )}

          {isTrackingPage && (
            <Stack alignItems={"flex-end"}>
              <Stack direction={"row"} gap={2}>
                {isTrackingPage &&
                  trackingPortalConfig?.social_media?.facebook && (
                    <Stack
                      direction={"row"}
                      gap={1}
                      fontFamily={typography.fontFamilySemiBold}
                      alignItems={"center"}
                      justifyContent={"flex-start"}
                    >
                      <FacebookIcon
                        sx={{ color: portalConfig?.footer?.text_color }}
                      />
                      {trackingPortalConfig?.social_media?.facebook ? (
                        <a
                          href={
                            trackingPortalConfig?.social_media?.facebook &&
                            (trackingPortalConfig?.social_media?.facebook.startsWith(
                              "http://"
                            ) ||
                              trackingPortalConfig?.social_media?.facebook.startsWith(
                                "https://"
                              ))
                              ? trackingPortalConfig?.social_media?.facebook
                              : `https://${trackingPortalConfig?.social_media?.facebook}`
                          }
                          target="_blank"
                          style={{
                            color: portalConfig?.footer?.text_color,
                            fontSize: "14px",
                          }}
                          rel="noopener noreferrer"
                        >
                          Facebook
                        </a>
                      ) : (
                        <Typography
                          sx={{
                            color: portalConfig?.footer?.text_color,
                            fontSize: "14px",
                          }}
                        >
                          Facebook
                        </Typography>
                      )}
                    </Stack>
                  )}
                {isTrackingPage &&
                  trackingPortalConfig?.social_media?.instagram && (
                    <Stack
                      direction={"row"}
                      gap={1}
                      alignItems={"center"}
                      justifyContent={"flex-start"}
                    >
                      <InstagramIcon
                        sx={{ color: portalConfig?.footer?.text_color }}
                      />
                      {trackingPortalConfig?.social_media?.instagram ? (
                        <a
                          href={
                            trackingPortalConfig?.social_media?.instagram &&
                            (trackingPortalConfig?.social_media?.instagram.startsWith(
                              "http://"
                            ) ||
                              trackingPortalConfig?.social_media?.instagram.startsWith(
                                "https://"
                              ))
                              ? trackingPortalConfig?.social_media?.instagram
                              : `https://${trackingPortalConfig?.social_media?.instagram}`
                          }
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{
                            color: portalConfig?.footer?.text_color,
                            fontSize: "14px",
                          }}
                        >
                          Instagram
                        </a>
                      ) : (
                        <Typography
                          sx={{
                            color: portalConfig?.footer?.text_color,
                            fontSize: "14px",
                          }}
                        >
                          Instagram
                        </Typography>
                      )}
                    </Stack>
                  )}
              </Stack>
            </Stack>
          )}
        </Stack>
      )}
      <Dialog
        fullWidth={true}
        open={isPolicyModalOpen}
        onClose={() => setPolicyModalOpen(false)}
        maxWidth="xs"
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Return policy
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => setPolicyModalOpen(false)}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers sx={{ overflowY: "auto", maxHeight: "500px" }}>
          <div
            dangerouslySetInnerHTML={{
              __html: brandPortalConfig?.return_policy?.custom_content,
            }}
          ></div>
        </DialogContent>
      </Dialog>
    </Stack>)
};

export default ReturnsLayout;
