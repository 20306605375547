import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Helmet } from "react-helmet-async";
import { Stack, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import {
  TextField,
  Box,
  Button,
  Typography,
  Snackbar,
  Alert,
  FormControl,
  FormLabel,
  FormControlLabel,
  Radio,
  RadioGroup,
  Select,
  MenuItem,
  InputLabel,
  Switch,
  Modal,
  IconButton,
  Grid,
  Divider,
} from "@mui/material";
import { getUserInfo, updateUserInfo } from "../features/user/userState";
import {
  getConfigData,
  getConfigUpdated,
} from "../features/config/configState";
import { useAppDispatch, useAppSelector } from "../hooks/hooks";
import typography from "src/theme/typography";
import palette from "src/theme/palette";
import SigninCarrier from "src/sections/auth/signin/formsContainer/carrier/signinCarrier/signinCarrier";
import SigninManage from "src/sections/auth/signin/formsContainer/manage/signinManage/signinManage";
import { setSigninData } from "src/features/signin/signin.action";
import {
  getTenant,
  getTenantUser,
  getUsersList,
} from "src/features/usersPage/usersApi";
import { getPlans } from "src/features/onborading/onboardingApi";
import {
  updateLogo,
  updateTenantInfo,
  retrieveLogoUrl,
  updateEmailPreferences,
  updateCarrierManagerInfo,
} from "src/features/config/configApi";
import ModalComponent from "src/components/modal/ModalComponent";
import { LoadingButton } from "@mui/lab";
import { getPlansInfo } from "src/features/plans/plansState";
import planNames from "src/utils/planNames";
import { unixToDate } from "src/utils/core";
import ManageForm from "src/sections/auth/signin/formsContainer/manage/manage";
import Carrier from "src/sections/auth/signin/formsContainer/carrier/carrier";
import InfoLogo from "src/components/info-logo/info-logo";
import {
  decrementAwaitingApiCounter,
  incrementAwaitingApiCounter,
} from "src/features/ui/uiState";
import { emailConfigurationTexts } from "./ReturnConfiguration/ReturnMethod/EmailConfiguration/EmailConfigurationTexts";
import EmailTemplateItem from "src/components/EmailTemplates/EmailTemplateItem";
import EmailTemplateEdition from "src/pages/ReturnConfiguration/EmailTemplate/EmailTemplateEdition";
import PreviewTemplate from "src/pages/ReturnConfiguration/EmailTemplate/PreviewTemplate";
import {
  getEmailTemplatesConfig,
  getEmailTemplatesList,
} from "src/features/returnConfiguration/emailTemplatesApi";
import { getUserNameById } from "src/utils/getUserNameById";
import moment from "moment";

const styleInput = {
  input: { color: palette.common.black },
  width: "100%",
  marginTop: "10px",

  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "secondary.light",
  },
  "& .MuiInputLabel-formControl": {
    color: "#00000099",
  },
  "& .MuiFormLabel-root-MuiInputLabel-root.Mui-focused": {
    color: "#00000099",
  },
  "& .MuiFormHelperText-root": {
    textAlign: "left",
    fontSize: "14px",
  },
};

const btnStyle = {
  width: { xs: 218, lg: 265 },
  height: 48,
  boxShadow: "none",
  fontSize: 16,
  fontFamily: typography.fontFamilySemiBold,
};

const textStyle = {
  color: palette.common.black,
  fontSize: 16,
};
const popUpModalStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: "80%", sm: "auto", md: "auto" },
  borderRadius: "20px",
  background: "#FFF",
  overflowX: "hidden",
  outline: "none",
};
{
  /* Shared Styles for Consistent Columns */
}
const columnStyle = {
  flex: 1,
  minWidth: "200px", // Optional: Ensures consistent width across items
};
export default function ConfigPage({
  emailTemplateLayout,
  setEmailTemplateLayout,
  emailTemplateContent,
  setEmailTemplateContent,
  emailTemplateSubject,
  setEmailTemplateSubject,
  emailTemplateBackendReponse,
  setEmailTemplateBackendReponse,
}) {
  const { reset } = useForm();
  const dispatchRedux = useDispatch();
  const inputFile = useRef(null);
  const signinData = useSelector((state) => state.signin.signinData);
  const [activeTab, setActiveTab] = useState("0");
  const [showConfigUpdated, setShowConfigUpdated] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [saveEnable, setsaveEnable] = useState(true);

  const [isConfig, setIsConfig] = useState(false);
  const [isConfigCarrierOrderManager, setIsConfigCarrierOrderManager] =
    useState(false);
  const [userConfigInfo, setUserConfigInfo] = useState({});
  const [tenantConfigInfo, setTenantConfigInfo] = useState({});
  const [contactEmail, setContactEmail] = useState("");
  const [contactEmailError, setContactEmailError] = useState(false);
  const [shippingVolume, setShippingVolume] = useState("");
  const [plans, setPlans] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState("");
  const [planSwitch, setPlanSwitch] = useState("");
  const [logoPath, setLogoPath] = useState();
  const [file, setFile] = useState();
  const [fileError, setFileError] = useState(false);
  const [isLogoUpdate, setIsLogoUpdate] = useState(false);
  const [isActivate, setIsActivate] = useState("active");
  const [isSwitched, setIsSwitched] = useState(false);
  const [adminUsers, setAdminUsers] = useState([]);
  const [redirect, setRedirect] = useState("");
  const [openEditTemplateUI, setOpenEditTemplateUI] = useState("");
  const [openPreview, setOpenPreview] = useState(false);
  const [templateNameSelected, setTemplateNameSelected] = useState(false);
  const [emailTemplateList, setEmailTemplateList] = useState([]);
  const [logoFile, setLogoFile] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [showEditPreviewTemplateBtns, setShowEditPreviewTemplateBtns] =
    useState(false);
  const [lastUpdatedInfo, setLastUpdatedInfo] = useState("No updated yet");
  const [templateLastUpdateList, setTemplateLastUpdateList] = useState([]);
  const [emailTemplateItemData, setEmailTemplateItemData] = useState(
    emailConfigurationTexts.delveryEmailTemplateArr
  );

  const [emailPreferences, setEmailPreferences] = useState({
    first_scan_email: true,
    delivered_email: true,
    out_for_delivery_email: true,
  });

  const [isEmailPreferencesUpdate, setIsEmailPreferencesUpdate] =
    useState(false);

  const dispatch = useAppDispatch();
  const userInfo = useAppSelector(getUserInfo);
  const configData = useAppSelector(getConfigData);
  const isConfigUpdated = useAppSelector(getConfigUpdated);

  const plansInfo = useAppSelector(getPlansInfo);
  const isDemoPlan = userInfo?.plan_id === plansInfo[planNames.demo]?._id;

  const headers = {
    tenant_id: userInfo?.tenant_id,
    user_id: userInfo?.user_id,
  };

  const defaultValue = {
    REFRESH_STEP_FUNC_ARN: configData?.aws?.refresh_step_func_arn || "",
    BUCKET_NAME: configData?.aws?.bucket_name || "",
    BUCKET_FOLDER_NAME: configData?.aws?.bucket_folder_name || "",
    ITERABLE_API_KEY: configData?.iterable?.api_key || "",
    ITERABLE_URL: configData?.iterable?.url || "",
    ITERABLE_CAMPAIGN_ID: configData?.iterable?.campaign_id || "",
    SERVICE_UPS_USERNAME: configData?.ups?.ups_username || "",
    ACCESS_TOKEN: configData?.magento?.access_token || "",
    BASE_URL: configData?.website?.base_url || "",
    MAGENTO_BASE_URL: configData?.magento?.magento_base_url || "",
    EMAIL_FROM: configData?.report?.email_from || "",
    EMAIL_TO: configData?.report?.email_to || "",
    IS_PROD: configData?.website?.is_prod || "",
    ITERABLE_API_KEY: configData?.iterable?.api_key || "",
    SERVICE_UPS_LICENCE_NUMBER: configData?.ups?.ups_license_no || "",
    SERVICE_UPS_PASSWORD: configData?.ups?.ups_password || "",
    SERVICE_USPS_USER_ID: configData?.usps?.usps_user_id || "",
    // TODO: make sure this is working
    SERVICE_FEDEX_USERNAME: configData?.fedex?.fedex_username || "",
    SERVICE_FEDEX_LICENCE_NUMBER: configData?.fedex?.fedex_license_no || "",
    SERVICE_FEDEX_PASSWORD: configData?.fedex?.fedex_password || "",
  };

  const validateEmail = (email) => {
    return /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(email);
  };

  const onCloseExportInfo = () => {
    setShowConfigUpdated(false);
  };

  const onChangeClick = (name) => {
    setRedirect(name);
    dispatchRedux(
      setSigninData({
        ...signinData,
        user_id: userInfo?.user_id,
      })
    );
    const carriers = ["ups", "usps", "fedex", "dhl"];
    const orders = ["magento", "shopify", "shipstation"];

    if (carriers.includes(name)) {
      setIsConfig("carrier");
    }
    if (orders.includes(name)) {
      setIsConfig("manager");
    }
  };
  useEffect(() => {
    if (file) {
      handleUpdateLogo(file); 
    }
  }, [file]);
  
  const handleUpdateCarrierAndOrderManager = (name) => {
    dispatchRedux(
      setSigninData({
        ...signinData,
        user_id: userInfo?.user_id,
      })
    );
    name === "carrier"
      ? setIsConfigCarrierOrderManager("carrier")
      : setIsConfigCarrierOrderManager("manager");
  };

  const onSaveClick = () => {
    if (checkSignCarrier()) return;
    if (checkSignManager()) return;

    dispatch(incrementAwaitingApiCounter());

    const data = {};

    if (signinData.carrier.usps) {
      data.carrier = {
        ...data.carrier,
        usps: {
          is_label_generate: signinData.carrierSignUSPSIsLabelGenerate,
          user_name: signinData.carrierSignUSPSUsername,
          password: signinData.carrierSignUSPSPassword,
          client_key: signinData.carrierSignUSPSClientId,
          client_secret: signinData.carrierSignUSPSClientSecret,
          ...(signinData.carrierSignUSPSIsLabelGenerate && {
            crid: signinData.carrierSignUSPSCRID,
            manifest_id: signinData.carrierSignUSPSManifestMID,
            mid: signinData.carrierSignUSPSMID,
            account_number: signinData.carrierSignUSPSAccountNumber,
          }),
        },
      };
    }

    if (signinData.carrier.ups) {
      data.carrier = {
        ...data.carrier,
        ups: {
          user_name: signinData.carrierSignUPSUsername,
          password: signinData.carrierSignUPSPassword,
          client_key: signinData.carrierSignUPSClientId,
          client_secret: signinData.carrierSignUPSClientSecret,
        },
      };
    }

    if (signinData.carrier.fedex) {
      data.carrier = {
        ...data.carrier,
        fedex: {
          user_name: signinData.carrierSignFedexUsername,
          password: signinData.carrierSignFedexPassword,
          client_key: signinData.carrierSignFedexClientId,
          client_secret: signinData.carrierSignFedexClientSecret,
        },
      };
    }

    if (signinData.carrier.dhl) {
      data.carrier = {
        ...data.carrier,
        dhl: {
          user_name: signinData.carrierSignDHLUsername,
          password: signinData.carrierSignDHLPassword,
          api_key: signinData.carrierSignDHLApiKey,
          api_secret: signinData.carrierSignDHLApiSecret,
        },
      };
    }

    if (signinData.manager === "magento") {
      data.order_management = {
        magento: {
          store_url: signinData?.managerSignMagentoUrl,
          access_token: signinData?.managerSignMagentoToken,
        },
      };
    } else if (signinData.manager === "shopify") {
      data.order_management = {
        shopify: {
          access_token: signinData.managerSignShopifyApiKey,
          store_url: signinData.managerSignShopifyStoreUrl,
        },
      };
    } else if (signinData.manager === "shipstation") {
      data.order_management = {
        ship_station: {
          api_key: signinData.managerSignShipStationApiKey,
          api_secret: signinData.managerSignShipStationApiSecret,
        },
      };
    }

    updateCarrierManagerInfo(data, userInfo)
      .then((r) => {
        setShowConfirmation(true);
        setIsConfig(false);
        dispatch(decrementAwaitingApiCounter());
      })
      .catch(() => {
        setIsConfig(false);
        dispatch(decrementAwaitingApiCounter());
      });
  };

  const checkSignCarrier = () => {
    if (signinData.carrier.usps) {
      if (!signinData.carrierSignUSPSUsername) {
        dispatch(
          setSigninData({
            ...signinData,
            carrierSignUSPSUsernameError: true,
          })
        );
        return true;
      }

      if (!signinData.carrierSignUSPSPassword) {
        dispatch(
          setSigninData({
            ...signinData,
            carrierSignUSPSPasswordError: true,
          })
        );
        return true;
      }

      if (!signinData.carrierSignUSPSClientId) {
        dispatch(
          setSigninData({
            ...signinData,
            carrierSignUSPSClientIdError: true,
          })
        );
        return true;
      }

      if (!signinData.carrierSignUSPSClientSecret) {
        dispatch(
          setSigninData({
            ...signinData,
            carrierSignUSPSClientSecretError: true,
          })
        );
        return true;
      }
    }

    if (signinData.carrier.ups) {
      if (!signinData.carrierSignUPSUsername) {
        dispatch(
          setSigninData({
            ...signinData,
            carrierSignUPSUsernameError: true,
          })
        );
        return true;
      }
      if (!signinData.carrierSignUPSPassword) {
        dispatch(
          setSigninData({
            ...signinData,
            carrierSignUPSPasswordError: true,
          })
        );
        return true;
      }
    }

    if (signinData.carrier.fedex) {
      if (!signinData.carrierSignFedexUsername) {
        dispatch(
          setSigninData({
            ...signinData,
            carrierSignFedexUsernameError: true,
          })
        );
        return true;
      }
      if (!signinData.carrierSignFedexPassword) {
        dispatch(
          setSigninData({
            ...signinData,
            carrierSignFedexPasswordError: true,
          })
        );
        return true;
      }
    }

    if (signinData.carrier.dhl) {
      if (!signinData.carrierSignDHLUsername) {
        dispatch(
          setSigninData({
            ...signinData,
            carrierSignDHLUsernameError: true,
          })
        );
        return true;
      }

      if (!signinData.carrierSignDHLPassword) {
        dispatch(
          setSigninData({
            ...signinData,
            carrierSignDHLPasswordError: true,
          })
        );
        return true;
      }
    }
  };

  const checkSignManager = () => {
    if (!signinData.managerSignMagentoUrl && signinData.manager === "magento") {
      dispatch(
        setSigninData({
          ...signinData,
          managerSignMagentoUrlErrors: true,
        })
      );
      return true;
    }

    if (
      !signinData.managerSignMagentoToken &&
      signinData.manager === "magento"
    ) {
      dispatch(
        setSigninData({
          ...signinData,
          managerSignMagentoTokenErrors: true,
        })
      );
      return true;
    }

    if (
      !signinData.managerSignShopifyApiKey &&
      signinData.manager === "shopify"
    ) {
      dispatch(
        setSigninData({
          ...signinData,
          managerSignShopifyApiKeyErrors: true,
        })
      );
      return true;
    }

    if (
      !signinData.managerSignShipStationApiKey &&
      signinData.manager === "shipstation"
    ) {
      dispatch(
        setSigninData({
          ...signinData,
          managerSignShipStationApiKeyErrors: true,
        })
      );
      return true;
    }

    if (
      !signinData.managerSignShipStationApiSecret &&
      signinData.manager === "shipstation"
    ) {
      dispatch(
        setSigninData({
          ...signinData,
          managerSignShipStationApiSecretErrors: true,
        })
      );
      return true;
    }
  };

  const onUpdateLogoClick = () => {
    setFileError(false);
    const { current } = inputFile;
    (current || { click: () => {} }).click();
  };

  const handleTenantInfoUpdate = () => {
    if (!contactEmail || !validateEmail(contactEmail)) {
      setContactEmailError(true);
      return;
    }
    dispatch(incrementAwaitingApiCounter());

    const data = {
      shipping_volume: shippingVolume,
      status: isActivate,
      contact_info: {
        email: contactEmail,
      },
      plan_id: planSwitch,
      switched_to_premium:
        planSwitch === plansInfo[planNames.premium]?._id && isSwitched
          ? true
          : false,
    };

    updateTenantInfo(data, userInfo)
      .then((res) => {
        setIsSwitched(false);
        const { statusCode } = res;

        if (statusCode === 200) {
          getTenantUser(null, headers, userInfo?.user_id, userInfo)
            .then((r) => {
              const { data, statusCode } = r;

              if (statusCode === 200) {
                setUserConfigInfo(data);
              }
            })
            .catch((e) => console.log(e));

          getTenant(userInfo)
            .then((r) => {
              const { statusCode, data } = r;

              if (statusCode === 200) {
                setTenantConfigInfo(data);
                setShippingVolume(data.shipping_volume);
                setContactEmail(data.contact_info.email);
                setSelectedPlan(data.plan_id);
                setIsActivate(data.status);
                setLogoPath(
                  data.logo_path ? retrieveLogoUrl + data.logo_path : null
                );

                dispatch(decrementAwaitingApiCounter());

                dispatch(updateUserInfo(data))
                  .then(() => {
                    setShowConfirmation(true);
                  })
                  .catch((err) => {});
              }
            })
            .catch((e) => {
              dispatch(decrementAwaitingApiCounter());
            });
        }
      })
      .catch((e) => {
        dispatch(decrementAwaitingApiCounter());
      });
  };

  const handleUpdateLogo = (fileToUpload = file) => {
    if (!fileToUpload) {
      setFileError(true);
      return;
    }
  
    dispatch(incrementAwaitingApiCounter());
  
    fetch(fileToUpload)
      .then((res) => res.blob())
      .then((blob) => {
        
        const logoFile = new File([blob],
          
          fileToUpload === "/assets/no-image.png" 
            ?"no_logo": "company_logo", {
          type: fileToUpload === "/assets/no-image.png" ? "image/png" : blob.type,
        });
  
        updateLogo(logoFile, userInfo)
          .then((r) => {
            const { statusCode, data } = r;
  
            if (statusCode === 200) {
              const { file_path } = data;
              dispatch(updateUserInfo({ tenant_logo: file_path }))
                .then(() => {
                  dispatch(decrementAwaitingApiCounter());
                  setIsLogoUpdate(true);
                  setShowConfirmation(true);
                })
                .catch(() => {
                  dispatch(decrementAwaitingApiCounter());
                });
            }
          })
          .catch(() => {
            dispatch(decrementAwaitingApiCounter());
          });
      });
  };
  
  const handleCarrierManagerLoad = ({ carrier, order_management }) => {
    const data = {};

    if (carrier.ups) {
      data.carrierSignUPSUsername = carrier.ups.user_name;
      data.carrierSignUPSPassword = carrier.ups.password;
      data.carrierSignUPSClientId = carrier.ups.client_key;
      data.carrierSignUPSClientSecret = carrier.ups.client_secret;
      data.carrier = { ...data.carrier, ups: true };
    }

    if (carrier.usps) {
      data.carrierSignUSPSIsLabelGenerate = carrier.usps.is_label_generate;
      data.carrierSignUSPSUsername = carrier.usps.user_name;
      data.carrierSignUSPSPassword = carrier.usps.password;
      data.carrierSignUSPSClientId = carrier.usps.client_key;
      data.carrierSignUSPSClientSecret = carrier.usps.client_secret;

      if (carrier.usps.is_label_generate) {
        data.carrierSignUSPSCRID = carrier.usps.crid;
        data.carrierSignUSPSManifestMID = carrier.usps.manifest_id;
        data.carrierSignUSPSMID = carrier.usps.mid;
        data.carrierSignUSPSAccountNumber = carrier.usps.account_number;
      }

      data.carrier = { ...data.carrier, usps: true };
    }

    if (carrier.fedex) {
      data.carrierSignFedexUsername = carrier.fedex.user_name;
      data.carrierSignFedexPassword = carrier.fedex.password;
      data.carrierSignFedexClientId = carrier.fedex.client_key;
      data.carrierSignFedexClientSecret = carrier.fedex.client_secret;
      data.carrier = { ...data.carrier, fedex: true };
    }

    if (carrier.dhl) {
      data.carrierSignDHLUsername = carrier.dhl.user_name;
      data.carrierSignDHLPassword = carrier.dhl.password;
      data.carrierSignDHLApiKey = carrier.dhl.api_key;
      data.carrierSignDHLApiSecret = carrier.dhl.api_secret;
      data.carrier = { ...data.carrier, dhl: true };
    }

    if (order_management.magento) {
      data.managerSignMagentoUrl = order_management.magento.store_url;
      data.managerSignMagentoToken = order_management.magento.access_token;
      data.manager = "magento";
    }

    if (order_management.shopify) {
      data.managerSignShopifyStoreUrl = order_management.shopify.store_url;
      data.managerSignShopifyApiKey = order_management.shopify.access_token;
      data.manager = "shopify";
    }

    if (order_management.ship_station) {
      data.managerSignShipStationApiKey = order_management.ship_station.api_key;
      data.managerSignShipStationApiSecret =
        order_management.ship_station.api_secret;
      data.manager = "shipstation";
    }

    data.isFromConfig = true;
    dispatchRedux(setSigninData(data));
  };

  const handleUpdateEmailPreferences = async () => {
    dispatch(incrementAwaitingApiCounter());

    try {
      const response = await updateEmailPreferences(emailPreferences, userInfo);
      dispatch(decrementAwaitingApiCounter());

      if (response?.status === 200) {
        setIsEmailPreferencesUpdate(true);
        setShowConfirmation(true);

        getTenantUser(null, headers, userInfo?.user_id, userInfo)
          .then((r) => {
            const { data, statusCode } = r;

            if (statusCode === 200) {
              setUserConfigInfo(data);
            }
          })
          .catch((e) => console.log(e));

        getTenant(userInfo)
          .then((r) => {
            const { statusCode, data } = r;

            if (statusCode === 200) {
              setEmailPreferences({
                delivered_email: data.delivered_email,
                first_scan_email: data.first_scan_email,
                out_for_delivery_email: data.out_for_delivery_email,
              });
            }
          })
          .catch((e) => console.log(e));
      }
    } catch (error) {
      dispatch(decrementAwaitingApiCounter());
      console.log(error);
    }
  };

  const handleCloseModal = () => {
    setShowConfirmation(false);
    setIsEmailPreferencesUpdate(false);
    setIsLogoUpdate(false);
    window.location.reload();
  };

  useEffect(() => {
    dispatch(incrementAwaitingApiCounter());

    getTenantUser(null, headers, userInfo?.user_id, userInfo)
      .then((r) => {
        const { data, statusCode } = r;
        if (statusCode === 200) {
          setUserConfigInfo(data);
        }
      })
      .catch((e) => console.log(e));

    getTenant(userInfo)
      .then((r) => {
        const { statusCode, data } = r;

        if (statusCode === 200) {
          setTenantConfigInfo(data);
          setShippingVolume(data?.shipping_volume);
          setContactEmail(data?.contact_info.email);
          setSelectedPlan(data?.plan_id);
          setPlanSwitch(data?.plan_id);
          setIsActivate(data?.status);
          setLogoPath(data.logo_path ? retrieveLogoUrl + data.logo_path : null);
          setEmailPreferences({
            delivered_email: data?.delivered_email,
            first_scan_email: data?.first_scan_email,
            out_for_delivery_email: data?.out_for_delivery_email,
          });

          handleCarrierManagerLoad(data);
        }
      })
      .catch((e) => console.log(e));

    getPlans(isDemoPlan)
      .then((r) => {
        const { data, statusCode } = r;
        if (statusCode === 200) setPlans(data);
        dispatch(decrementAwaitingApiCounter());
      })
      .catch((error) => {
        console.log(error);
      });

    getUsersList(userInfo)
      .then((r) => {
        const { data, statusCode } = r;
        if (statusCode === 200) {
          const filteredArray = data.filter((r) => r.role === "administrator");
          setAdminUsers(filteredArray);
        }
      })
      .catch((e) => console.log(e));
  }, [activeTab]);

  useEffect(() => {
    setShowConfigUpdated(isConfigUpdated);
  }, [isConfigUpdated]);

  useEffect(() => {
    reset(defaultValue);
  }, [reset, configData]);

  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
    setIsConfig(false);
    setIsConfigCarrierOrderManager(false)
    setOpenEditTemplateUI("");
  };
  const handleLastUpdatedInfo = async (currentTextData) => {
    if (templateLastUpdateList?.length > 0) {
      const currentTemplate = templateLastUpdateList.find(
        (updateInfo) =>
          updateInfo.template_name === currentTextData.templateName
      );
      if (currentTemplate) {
        const updatedById =
          currentTemplate.updated_by || currentTemplate.created_by;
        const updatedAt =
          currentTemplate.updated_at || currentTemplate.created_at;

        const updatedByName =
          (await getUserNameById(userInfo, updatedById)) || "Unknown User";

        return `${updatedByName} on ${moment
          .unix(updatedAt)
          .tz("America/Los_Angeles")
          .format("MMM DD, YYYY")}`;
      } else return "No updated yet";
    } else return "No updated yet";
  };
  useEffect(() => {
    const getEmailTemplateInfo = async () => {
      dispatch(incrementAwaitingApiCounter());

      try {
        const configResponse = await getEmailTemplatesConfig(userInfo);
        const templatesResponse = await getEmailTemplatesList(userInfo);

        if (
          configResponse?.statusCode === 200 &&
          templatesResponse?.statusCode === 200
        ) {
          const configData = configResponse.data;

          setLogoFile(configData?.value?.brand_logo || "/assets/no-img.svg");
          const templateData = templatesResponse.data;
          setEmailTemplateList(templateData);

          const lastUpdateDate = templateData.map((el) => ({
            template_name: el.template_name,
            updated_at: el.updated_at,
            updated_by: el.updated_by,
          }));
          setTemplateLastUpdateList(lastUpdateDate);
        }

        dispatch(decrementAwaitingApiCounter());
      } catch (error) {
        console.error("Error fetching template info:", error);
        dispatch(decrementAwaitingApiCounter());
      }
    };

    getEmailTemplateInfo();
  }, [refresh, openEditTemplateUI]);

  useEffect(() => {
    const updateSelectableItemData = async () => {
      dispatch(incrementAwaitingApiCounter());

      let updatedData = [...emailConfigurationTexts?.delveryEmailTemplateArr];

      updatedData = await Promise.all(
        updatedData.map(async (el) => {
          const lastUpdated = await handleLastUpdatedInfo(el);
          return { ...el, lastUpdatedInfo: lastUpdated };
        })
      );

      setEmailTemplateItemData(updatedData);
      dispatch(decrementAwaitingApiCounter());
    };

    updateSelectableItemData();
  }, [emailConfigurationTexts, templateLastUpdateList]);

  return (
    <Box>
      <Helmet>
        <title> Configuration | BeyondCarts </title>
      </Helmet>
      <TabContext value={activeTab}>
        <Box sx={{ borderBottom: 1, borderColor: "divider", paddingTop: 2 }}>
          <TabList onChange={handleChange}>
            <Tab label="Company Information" value="0" />
            <Tab label="Logo" value="1" />
            <Tab label="Email Preferences" value="2" />
            <Tab label="Carriers" value="3" />
            <Tab label="Order Managers" value="4" />
          </TabList>
        </Box>
        {!isConfig && !isConfigCarrierOrderManager && (
          <Box
            sx={{
              paddingY: { xs: 1, md: 2 },
              display: "flex",
              flexDirection: "column",
              gap: 4,
            }}
          >
            <TabPanel value="0">
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: { xs: 3, md: 4 },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: { xs: 2, md: 4 },
                    width: "100%",
                  }}
                >
                  <Typography sx={{ ...textStyle, ...columnStyle }}>
                    Company name:{" "}
                    <span style={{ fontWeight: "bold" }}>
                      {tenantConfigInfo?.company_name}
                    </span>
                  </Typography>

                  <Typography sx={{ ...textStyle, ...columnStyle }}>
                    Enrolled at: {unixToDate(tenantConfigInfo?.enrolled_at)}
                  </Typography>

                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 1,
                      ...columnStyle,
                    }}
                  >
                    <Typography
                      sx={{
                        ...textStyle,
                        // marginTop: 0,
                      }}
                    >
                      Status:
                    </Typography>
                    <Switch
                      checked={isActivate === "active" ? true : false}
                      size="medium"
                      onChange={(event) =>
                        setIsActivate(
                          event.target.checked ? "active" : "Inactive"
                        )
                      }
                    ></Switch>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: { xs: 2, md: 4 },
                    width: "100%",
                  }}
                >
                  <Box sx={{ ...columnStyle }}>
                    {selectedPlan === plansInfo[planNames.premium]?._id ||
                    selectedPlan === plansInfo[planNames.demo]?._id ? (
                      <Typography sx={{ ...textStyle }}>
                        Plan name:{" "}
                        {plans.find((el) => el._id === selectedPlan)?.name ||
                          "N/A"}
                      </Typography>
                    ) : (
                      <FormControl
                        fullWidth
                        sx={{
                          ...styleInput,
                          //  marginTop: 0
                        }}
                      >
                        <InputLabel id="credit-month-select-label">
                          Plan name
                        </InputLabel>
                        <Select
                          labelId="credit-month-select-label"
                          value={planSwitch}
                          label="Plan name"
                          onChange={(e) => {
                            setIsSwitched(true);
                            setPlanSwitch(e.target.value);
                            // setSelectedPlan(e.target.value);
                          }}
                          sx={{
                            ...styleInput,
                            color: palette.common.black,
                            // marginTop: 0,
                          }}
                        >
                          <MenuItem
                            value={plansInfo[planNames.free]?._id}
                            disabled
                            sx={{ color: palette.common.black }}
                          >
                            {
                              plans.find(
                                (p) => p._id === plansInfo[planNames.free]?._id
                              )?.name
                            }
                          </MenuItem>
                          {plans.map(
                            (p) =>
                              p._id != plansInfo[planNames.free]?._id &&
                              p._id != plansInfo[planNames.demo]?._id && (
                                <MenuItem
                                  key={p._id}
                                  value={p._id}
                                  sx={{ color: palette.common.black }}
                                >
                                  {p.name}
                                </MenuItem>
                              )
                          )}
                        </Select>
                      </FormControl>
                    )}
                  </Box>
                  <Box sx={{ ...columnStyle }}>
                    {adminUsers.length > 1 ? (
                      <FormControl fullWidth sx={styleInput}>
                        <InputLabel id="credit-month-select-email">
                          Primary contact
                        </InputLabel>
                        <Select
                          labelId="credit-month-select-email"
                          value={contactEmail}
                          label="Primary contact"
                          onChange={(e) => setContactEmail(e.target.value)}
                          sx={{
                            ...styleInput,
                            color: palette.common.black,
                            // marginTop: 0,
                          }}
                        >
                          {adminUsers.map((a, index) => (
                            <MenuItem
                              key={index}
                              value={a.user_name}
                              sx={{ color: palette.common.black }}
                            >
                              {`${a.first_name} ${a.last_name}`}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    ) : (
                      <TextField
                        sx={styleInput}
                        label="Primary contact"
                        value={contactEmail}
                        disabled
                        error={contactEmailError}
                        helperText={contactEmailError && "Email not valid."}
                        onChange={(e) => setContactEmail(e.target.value)}
                        onFocus={() => setContactEmailError(false)}
                        inputProps={{ maxLength: 40 }}
                      />
                    )}
                  </Box>
                  <Box sx={{ ...columnStyle }}>
                    <Typography sx={{ ...textStyle }}>
                      Monthly Shipping Volume:
                      <RadioGroup
                        row
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue="female"
                        name="radio-buttons-group"
                        value={shippingVolume}
                        onClick={(e) => {
                          if (e.target.value === shippingVolume) {
                            setShippingVolume("");
                          } else {
                            setShippingVolume(e.target.value);
                          }
                        }}
                        sx={{ display: "inline-flex", marginLeft: 1 }}
                      >
                        <FormControlLabel
                          value="1-2500"
                          control={<Radio />}
                          label="1-2500"
                        />
                        <FormControlLabel
                          value="2501-5000"
                          control={<Radio />}
                          label="2501-5000"
                        />
                        <FormControlLabel
                          value="5000&Above"
                          control={<Radio />}
                          label="5000 & Above"
                        />
                      </RadioGroup>
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: { xs: 1, md: 3 },
                  }}
                >
                  <Button
                    size="large"
                    type="button"
                    variant="contained"
                    disabled={isDemoPlan}
                    sx={{
                      bgcolor: saveEnable ? "primary.light" : "info.button",
                      color: saveEnable ? "common.white" : "common.black",
                      ...btnStyle,
                      backgroundColor: palette.secondary.main,
                    }}
                    onClick={handleTenantInfoUpdate}
                  >
                    Update
                  </Button>
                </Box>
              </Box>
            </TabPanel>

            <TabPanel value="1">
             
              <Stack>
              <Box>
              <Grid container spacing={3} sx={{ pt: 1, pb: 3, pl: 2 }}>
            <Grid item xs={3}>
              <Box
                sx={{ display: "flex", gap: 1, mb: 1, alignItems: "center" }}
              >
                <Typography
                  fontSize={18}
                  fontFamily={typography.fontFamilySemiBold}
                >
                  Logo
                </Typography>
              </Box>
              <Typography sx={{ color: "#AAAAAA", fontSize: "14px" }}>
              Click on the image  to browse for new logo file
              </Typography>
            </Grid>
            <Grid item xs={9}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  backgroundColor: "white",
                  border: "1px solid #E6E1E1",
                  padding: "7px 10px 7px 10px",
                  borderRadius: "12px",
                  justifyContent: "space-between",
                }}
              >
                  <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
        <Box display="flex" flexDirection="column" alignItems="center" gap={2}>
          <Box sx={{ position: "relative", pl: 1 }}>
            <Box
              style={{
                cursor: "pointer",
                position: "relative",
              }}
            >
              <img src="/assets/img-frame.svg" alt="image frame" />
              
               <img
                  style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  maxHeight: 40,
                  display: "block",
                        }}
                        src={
                          file
                            ? file
                            : logoPath
                            ? logoPath
                            : "/assets/no-image.png"
                        }
                        onClick={onUpdateLogoClick}
                      />
            </Box>
            {/* <input
                    type="file"
                    id="file"
                    accept="image/*"
                    onChange={(e) => {
                      let reader = new FileReader();
                      try {
                        reader.readAsDataURL(e.target.files[0]);
                        reader.onloadend = function () {
                          setFile(reader.result);
                        };
                      } catch (error) {}
                    }}
                    ref={inputFile}
                    hidden
                  /> */}
                  <input
  type="file"
  id="file"
  accept="image/*"
  onChange={(e) => {
    const reader = new FileReader();
    try {
      const selectedFile = e.target.files[0];
      if (!selectedFile) {
        setFileError(true);
        return;
      }

      reader.onloadend = function () {
        setFile(reader.result); 
      };

      reader.readAsDataURL(selectedFile); 
    } catch (error) {
      console.error("Error reading file:", error);
    }
  }}
  ref={inputFile}
  hidden
/>
          </Box>
          {fileError && (
                      <Typography
                        sx={{
                          color: fileError ? "red" : "red",
                          textAlign: "center",
                          display:fileError?'block':'none'
                        }}
                      >
                        You must select one file.
                      </Typography>
                    )}
        </Box>
        <Box>
          <Typography sx={{ color: palette.primary.main, mb: 1 }}>
            Upload Logo
          </Typography>
          <Typography sx={{ color: "#AAAAAA", fontSize: "14px" }}>
            Format : JPG, PNG or SVG
          </Typography>
        </Box>
      </Box>
      
        {/* <Button
          variant="outlined"
          sx={{ width: "130px" }}
          onClick={handleUpdateLogo}>
          Update
        </Button> */}
    {( (logoPath && !logoPath.includes("no_logo"))) &&  (
    <Button
      variant="outlined"
      sx={{ width: "130px", mt: 2 }}
      onClick={() => {
        setFile("/assets/no-image.png"); // Reset to default image
        handleUpdateLogo("/assets/no-image.png"); // Call update handler
      }}
    >
      Remove
    </Button>
  )} 
      
    </Box>
              </Box>
            </Grid>
          </Grid>
              </Box>
              </Stack>
            </TabPanel>

            <TabPanel value="2">
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: { xs: 3, md: 4 },
                }}
              >
                {/* //---------previous email preference code----------
                 <Box
                // sx={{
                //   display: "flex",
                //   flexDirection: "column",
                //   alignItems: "center",
                //   justifyContent: "space-between",
                //   gap: 3,
                // }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: { xs: 1, md: 3 },
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <h3
                      style={{
                        ...typography.h3,
                        textAlign: "center",
                        width: "100%",
                      }}
                    >
                      Email Preferences
                    </h3>
                    <InfoLogo
                      infoTxt="Select the type of emails end customers want to receive."
                      contentPositionStyle={{
                        top: "140%",
                        left: " -480%",
                        backgroundColor: "white",
                      }}
                    />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: { xs: 0, md: 1 },
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography
                        sx={{
                          ...textStyle,
                          marginTop: 0,
                        }}
                      >
                        Carrier Origin Scan
                      </Typography>
                      <Switch
                        checked={emailPreferences.first_scan_email}
                        onChange={(event) =>
                          setEmailPreferences((prev) => {
                            return {
                              ...prev,
                              first_scan_email: event.target.checked,
                            };
                          })
                        }
                      />
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography sx={{ ...textStyle, margin: 0 }}>
                        Out For Delivery
                      </Typography>
                      <Switch
                        checked={emailPreferences.out_for_delivery_email}
                        onChange={(event) =>
                          setEmailPreferences((prev) => {
                            return {
                              ...prev,
                              out_for_delivery_email: event.target.checked,
                            };
                          })
                        }
                      />
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography
                        sx={{
                          ...textStyle,
                          margin: 0,
                        }}
                      >
                        Delivered Email
                      </Typography>
                      <Switch
                        checked={emailPreferences.delivered_email}
                        onChange={(event) =>
                          setEmailPreferences((prev) => {
                            return {
                              ...prev,
                              delivered_email: event.target.checked,
                            };
                          })
                        }
                      />
                    </Box>
                  </Box>
                </Box>

                <Box
                  sx={{
                    // height: 150,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Button
                    size="large"
                    type="button"
                    variant="contained"
                    disabled={isDemoPlan}
                    sx={{
                      bgcolor: saveEnable ? "primary.light" : "info.button",
                      color: saveEnable ? "common.white" : "common.black",
                      ...btnStyle,
                      backgroundColor: palette.secondary.main,
                    }}
                    onClick={handleUpdateEmailPreferences}
                  >
                    Update
                  </Button>
                </Box>
              </Box> */}
                {openEditTemplateUI ? (
                  <Stack
                    gap={2}
                    padding={5}
                    paddingTop={2}
                    width={"100%"}
                    sx={{
                      backgroundColor: "white",
                      border: "1px solid #D9D9D9",
                    }}
                  >
                    <EmailTemplateEdition
                      template_name={openEditTemplateUI}
                      setOpenEditTemplateUI={setOpenEditTemplateUI}
                      emailTemplateItemData={emailTemplateItemData}
                      logoFile={logoFile}
                      setRefresh={setRefresh}
                      emailTemplateLayout={emailTemplateLayout}
                      setEmailTemplateLayout={setEmailTemplateLayout}
                      emailTemplateContent={emailTemplateContent}
                      setEmailTemplateContent={setEmailTemplateContent}
                      emailTemplateSubject={emailTemplateSubject}
                      setEmailTemplateSubject={setEmailTemplateSubject}
                      emailTemplateBackendReponse={emailTemplateBackendReponse}
                      setEmailTemplateBackendReponse={
                        setEmailTemplateBackendReponse
                      }
                    />
                  </Stack>
                ) : (
                  <Stack>
                    {emailTemplateItemData.map((el, index) => (
                      <EmailTemplateItem
                        key={index}
                        data={el}
                        setOpenEditTemplateUI={setOpenEditTemplateUI}
                        setOpenPreview={setOpenPreview}
                        setTemplateNameSelected={setTemplateNameSelected}
                        emailTemplateList={emailTemplateList}
                        lastUpdatedInfo={el.lastUpdatedInfo}
                        showBtns={showEditPreviewTemplateBtns}
                        // hideCheckbox
                        hideDivider={index === emailTemplateItemData.length - 1}
                      />
                    ))}
                  </Stack>
                )}
              </Box>
            </TabPanel>

            <Snackbar
              open={showConfigUpdated}
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
              autoHideDuration={6000}
              onClose={onCloseExportInfo}
            >
              <Alert
                onClose={onCloseExportInfo}
                severity="success"
                sx={{ width: "100%" }}
              >
                Configuration updated.
              </Alert>
            </Snackbar>

            <TabPanel
              value="3"
              sx={{
                padding: 0,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  columnGap: "100px",
                  paddingBottom: "0",
                  // borderTop: "1px solid #c4c4c4",
                }}
              >
                <Box
                  sx={{
                    paddingTop: { xs: 3, md: 4 },
                    paddingX: { xs: 3, md: 4 },
                    paddingBottom: 2,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: { xs: 2, md: 4 },
                  }}
                >
                  {/* <h3
                style={{
                  ...typography.h3,
                  textAlign: "center",
                  width: "100%",
                }}
              >
                Carriers 
              </h3> */}

                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: { xs: 3, md: 6 },
                      flexDirection: { xs: "column", md: "row" },
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: { xs: "column", md: "row" },
                        gap: { xs: 3, md: 6 },
                      }}
                    >
                      {tenantConfigInfo?.carrier?.usps && (
                        <img
                          className="carrier-img"
                          src="/assets/usps.png"
                          style={{
                            cursor: "pointer",
                          }}
                          onClick={() => onChangeClick("usps")}
                        />
                      )}

                      {tenantConfigInfo?.carrier?.ups && (
                        <img
                          className="carrier-img"
                          src="/assets/ups.png"
                          style={{
                            cursor: "pointer",
                          }}
                          onClick={() => onChangeClick("ups")}
                        />
                      )}

                      {tenantConfigInfo?.carrier?.fedex && (
                        <img
                          className="carrier-img"
                          src="/assets/fedex.png"
                          style={{
                            cursor: "pointer",
                            width: "150px",
                          }}
                          onClick={() => onChangeClick("fedex")}
                        />
                      )}

                      {tenantConfigInfo?.carrier?.dhl && (
                        <img
                          className="carrier-img"
                          src="/assets/dhl.png"
                          style={{
                            cursor: "pointer",
                            width: "160px",
                          }}
                          onClick={() => onChangeClick("dhl")}
                        />
                      )}
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: { xs: "column", md: "row" },
                      gap: 2,
                      marginTop: { xs: 2, md: 0 },
                    }}
                  >
                    <Button
                      size="large"
                      type="button"
                      variant="contained"
                      sx={{
                        color: "common.white",
                        backgroundColor: palette.secondary.main,
                        ...btnStyle,
                        // width: { xs: 220, lg: 300 },
                        // height: { xs: 70, lg: 48 },
                        width: 220,
                      }}
                      onClick={() =>
                        handleUpdateCarrierAndOrderManager("carrier")
                      }
                    >
                      Update Carriers
                    </Button>
                  </Box>
                </Box>
              </Box>
            </TabPanel>
            <TabPanel
              value="4"
              sx={{
                padding: 0,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  columnGap: "100px",
                  paddingBottom: "0",
                }}
              >
                <Box
                  sx={{
                    paddingTop: { xs: 3, md: 4 },
                    paddingX: { xs: 3, md: 4 },
                    paddingBottom: 2,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: { xs: 2, md: 4 },
                  }}
                >
                  {/* <h3
                style={{
                  ...typography.h3,
                  textAlign: "center",
                  width: "100%",
                }}
              >
                Order Managers
              </h3> */}

                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: { xs: 3, md: 6 },
                      flexDirection: { xs: "column", md: "row" },
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: 6,
                      }}
                    >
                      {tenantConfigInfo?.order_management?.shopify && (
                        <img
                          className="manage-img"
                          src="/assets/shopify.png"
                          style={{
                            cursor: "pointer",
                          }}
                          onClick={() => onChangeClick("shopify")}
                        />
                      )}

                      {tenantConfigInfo?.order_management?.magento && (
                        <img
                          className="manage-img"
                          src="/assets/magento.png"
                          style={{
                            cursor: "pointer",
                          }}
                          onClick={() => onChangeClick("magento")}
                        />
                      )}

                      {tenantConfigInfo?.order_management?.ship_station && (
                        <img
                          className="manage-img manage-img2"
                          src="/assets/shipStation.png"
                          style={{
                            cursor: "pointer",
                          }}
                          onClick={() => onChangeClick("shipstation")}
                        />
                      )}
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: { xs: "column", md: "row" },
                      gap: 2,
                      marginTop: { xs: 2, md: 0 },
                    }}
                  >
                    <Button
                      size="large"
                      type="button"
                      variant="contained"
                      sx={{
                        color: "common.white",
                        backgroundColor: palette.secondary.main,
                        ...btnStyle,
                        // width: { xs: 220, lg: 300 },
                        // height: { xs: 70, lg: 48 },
                        width: 220,
                      }}
                      onClick={() =>
                        handleUpdateCarrierAndOrderManager("manager")
                      }
                    >
                      Update Order Managers
                    </Button>
                  </Box>
                </Box>
              </Box>
            </TabPanel>
          </Box>
        )}

        {isConfig && (
          <Box
            sx={{
              border: "1px solid #c4c4c4",
              borderRadius: "10px",
              padding: { xs: "24px 0px", md: "50px 100px" },
              marginTop: { xs: "20px", md: "30px" },
            }}
          >
            {isConfig === "carrier" ? (
              <SigninCarrier redirect={redirect} />
            ) : (
              <SigninManage redirect={redirect} />
            )}

            <Box
              sx={{
                paddingX: 2,
                paddingTop: "30px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: "auto",
                gap: { xs: 1, md: 2 },
              }}
            >
              <LoadingButton
                // fullWidth
                size="large"
                type="submit"
                variant="contained"
                onClick={() => {
                  setIsConfig(false);
                  window.location.reload();
                }}
                sx={{
                  fontSize: "16px !important",
                  width: { xs: 180, md: 260 },
                  boxShadow: "none",
                  backgroundColor: "grey.600",
                  "&:hover": {
                    backgroundColor: "primary.lightIcon",
                  },
                }}
              >
                Cancel
              </LoadingButton>

              <LoadingButton
                // fullWidth
                size="large"
                type="submit"
                variant="contained"
                disabled={isDemoPlan}
                onClick={onSaveClick}
                sx={{
                  fontSize: "16px !important",
                  width: { xs: 180, md: 260 },
                  boxShadow: "none",
                  backgroundColor: saveEnable
                    ? palette.secondary.main
                    : "info.button",
                  color: saveEnable ? "common.white" : "common.black",
                }}
              >
                Save
              </LoadingButton>
            </Box>
          </Box>
        )}

        {isConfigCarrierOrderManager && (
          <Box
            sx={{
              border: "1px solid #c4c4c4",
              borderRadius: "10px",
              padding: { xs: "24px 0px", md: "50px 100px" },
              marginTop: { xs: "20px", md: "30px" },
            }}
          >
            {isConfigCarrierOrderManager === "carrier" ? (
              <Carrier />
            ) : (
              <ManageForm />
            )}

            <Box
              sx={{
                paddingX: 2,
                paddingTop: "30px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: "auto",
                gap: { xs: 1, md: 2 },
              }}
            >
              <LoadingButton
                // fullWidth
                size="large"
                type="submit"
                variant="contained"
                onClick={() => {
                  setIsConfigCarrierOrderManager(false);
                  window.location.reload();
                  // dispatchRedux(
                  //   setSigninData({
                  //     ...originalSigninData,
                  //   })
                  // );
                }}
                sx={{
                  fontSize: "16px !important",
                  width: { xs: 180, md: 260 },
                  boxShadow: "none",
                  backgroundColor: "grey.600",
                  "&:hover": {
                    backgroundColor: "primary.lightIcon",
                  },
                }}
              >
                Cancel
              </LoadingButton>

              <LoadingButton
                // fullWidth
                size="large"
                type="submit"
                variant="contained"
                disabled={
                  signinData?.carrierError ||
                  signinData?.manageError ||
                  !signinData?.carrier ||
                  signinData?.carrier == null ||
                  !signinData?.manager ||
                  signinData?.manager == null ||
                  signinData?.manager == ""
                }
                onClick={() => {
                  setIsConfig(isConfigCarrierOrderManager);
                  setIsConfigCarrierOrderManager(false);
                }}
                sx={{
                  fontSize: "16px !important",
                  width: { xs: 180, md: 260 },
                  boxShadow: "none",
                  backgroundColor: saveEnable
                    ? palette.secondary.main
                    : "info.button",
                  color: saveEnable ? "common.white" : "common.black",
                }}
              >
                Continue
              </LoadingButton>
            </Box>
          </Box>
        )}

        <ModalComponent
          openModal={showConfirmation}
          handleCloseModal={handleCloseModal}
          handleBtnClick={handleCloseModal}
          title={"Configuration"}
          description={`${
            isLogoUpdate
              ? "Logo"
              : isEmailPreferencesUpdate
              ? "Email preferences"
              : "Information "
          } 
      updated succesfully.`}
          btnText={"Continue"}
        />
      </TabContext>
      <Modal open={openPreview} onClose={() => setOpenPreview(false)}>
        <Box sx={popUpModalStyle}>
          <IconButton
            aria-label="close"
            sx={{
              position: "fixed",
              top: 11,
              right: 11,
              zIndex: 2,
            }}
            onClick={() => setOpenPreview(false)}
          >
            <img width={25} src="/assets/icons/table/close.svg" alt="X" />
          </IconButton>
          <PreviewTemplate
            template_name={templateNameSelected}
            logoFile={logoFile}
            isRenderingFromModal
          />
        </Box>
      </Modal>
    </Box>
  );
}
