import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import { Box, Stack, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";

import { setSigninData } from "src/features/signin/signin.action";
import {
  createOrderExpiredDemo,
  createOrderOnBoarding,
} from "src/features/onborading/onboardingApi";
import { useAppSelector } from "src/hooks/hooks";
import { getUserInfo } from "src/features/user/userState";

import FormStepper from "./stepper/stepper";
import palette from "src/theme/palette";
import CircularLoader from "src/loader/circular/CircularLoader";
import AccountPopover from "src/layouts/dashboard/header/AccountPopover";

import "./formsContainer.scss";
import planNames from "src/utils/planNames";
import { getPlansInfo } from "src/features/plans/plansState";
import { createPaymentMethod } from "src/features/billing/billingApi";

const SigninFormContainer = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const signinData = useSelector((state) => state.signin.signinData);

  const [step, setStep] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [onBoardingError, setOnboardingError] = useState(false);
  const [onBoardginErrorMjs, setOnBoardingErrorMjs] = useState("");

  const userInfo = useAppSelector(getUserInfo);
  const plansInfo = useAppSelector(getPlansInfo);

  const isDemoExpired =
    (userInfo?.is_plan_expired &&
      userInfo?.plan_id === plansInfo[planNames.demo]?._id) ||
    userInfo?.plan_id === plansInfo[planNames.demo]?._id;


  const handleOnBoarding = () => {
    const data = {
      company_name: signinData.company,
      shipping_volume: signinData.shippingvolume,
      contact_info: {
        first_name: signinData.name,
        last_name: signinData.lastName,
        email: signinData.email,
        phone_number: signinData.phone,
      },
      plan_id: signinData.plan_id,
      name: signinData.fullName,
      card_no: signinData.creditCard,
      cvv: parseInt(signinData.cvv),
      exp_year: parseInt(signinData.expirationYear),
      exp_month: parseInt(signinData.expirationMonth),
      is_preferred: true,
      card_type: signinData.card_type,
      strip_customer_token: signinData.strip_customer_token,
      card_token_id: signinData.card_token_id,
      billing: {
        address: signinData.billingAddr,
        city: signinData.city,
        state: signinData.state,
        zip_code: signinData.zipcode,
        country: signinData.country,
      },
      status: "active",
      switched_to_premium: signinData?.switched_to_premium,
      plan_data: signinData.creditCard.slice(-4),
    };

    if (signinData.carrier.usps) {
      data.carrier = {
        ...data.carrier,
        usps: {
          is_label_generate: signinData.carrierSignUSPSIsLabelGenerate,
          user_name: signinData.carrierSignUSPSUsername,
          password: signinData.carrierSignUSPSPassword,
          client_key: signinData.carrierSignUSPSClientId,
          client_secret: signinData.carrierSignUSPSClientSecret,
          connection_success: signinData.isUspsValid,
          ...(signinData.carrierSignUSPSIsLabelGenerate && {
            crid: signinData.carrierSignUSPSCRID,
            manifest_id: signinData.carrierSignUSPSManifestMID,
            mid: signinData.carrierSignUSPSMID,
            account_number: signinData.carrierSignUSPSAccountNumber,
          }),
        },
      };
    }

    if (signinData.carrier.ups) {
      data.carrier = {
        ...data.carrier,
        ups: {
          user_name: signinData.carrierSignUPSUsername,
          password: signinData.carrierSignUPSPassword,
          client_key: signinData.carrierSignUPSClientId,
          client_secret: signinData.carrierSignUPSClientSecret,
          connection_success: signinData.isUpsValid,
        },
      };
    }

    if (signinData.carrier.dhl) {
      data.carrier = {
        ...data.carrier,
        dhl: {
          user_name: signinData.carrierSignDHLUsername,
          password: signinData.carrierSignDHLPassword,
          api_key: signinData.carrierSignDHLApiKey,
          api_secret: signinData.carrierSignDHLApiSecret,
          connection_success: signinData.isDHLValid,
        },
      };
    }

    if (signinData.carrier.fedex) {
      data.carrier = {
        ...data.carrier,
        fedex: {
          user_name: signinData.carrierSignFedexUsername,
          password: signinData.carrierSignFedexPassword,
          client_key: signinData.carrierSignFedexClientId,
          client_secret: signinData.carrierSignFedexClientSecret,
          connection_success: signinData.isFedexValid,
        },
      };
    }

    if (signinData.manager === "magento") {
      data.order_management = {
        magento: {
          store_url: signinData.managerSignMagentoUrl,
          access_token: signinData.managerSignMagentoToken,
          connection_success: signinData.isMagentoValid,
        },
      };
    } else if (signinData.manager === "shopify") {
      data.order_management = {
        shopify: {
          access_token: signinData.managerSignShopifyApiKey,
          store_url: signinData.managerSignShopifyStoreUrl,
          connection_success: signinData.isShopifyValid,
        },
      };
    } else if (signinData.manager === "shipstation") {
      data.order_management = {
        ship_station: {
          api_key: signinData.managerSignShipStationApiKey,
          api_secret: signinData.managerSignShipStationApiSecret,
          connection_success: signinData.isShipStationValid,
        },
      };
    }

    const trialExpiredData = {
      ...data,
      isMarketingUser: true,
    };

    {
      isDemoExpired
        ? createOrderExpiredDemo(userInfo, trialExpiredData)
            .then((r) => {
              const { statusCode } = r;

              if (statusCode === 200) {
                setSigninData({});
                setIsLoading(false);
                navigate("/demo-order-completed");
              }
            })
            .catch((e) => {
              if (e.message.includes("already exists"))
                setOnBoardingErrorMjs(
                  "Your company is already registered. Please contact your admin."
                );
              if (e.message.includes("card")) setOnBoardingErrorMjs(e.message);
              setIsLoading(false);
              setOnboardingError(true);
            })
        : createOrderOnBoarding(data, signinData.user_id)
            .then((r) => {
              const { statusCode } = r;

              if (statusCode === 200) {
                setSigninData({});
                setIsLoading(false);
                navigate("/welcome");
              }
            })
            .catch((e) => {
              if (e.message.includes("already exists"))
                setOnBoardingErrorMjs(
                  "Your company is already registered. Please contact your admin."
                );
              if (e.message.includes("card")) setOnBoardingErrorMjs(e.message);
              setIsLoading(false);
              setOnboardingError(true);
            });
    }

    setIsLoading(true);
  };

  const checkSignCarrier = () => {
    if (signinData.carrier.usps) {
      if (!signinData.carrierSignUSPSUsername) {
        dispatch(
          setSigninData({
            ...signinData,
            carrierSignUSPSUsernameError: true,
          })
        );
        return true;
      }

      if (!signinData.carrierSignUSPSPassword) {
        dispatch(
          setSigninData({
            ...signinData,
            carrierSignUSPSPasswordError: true,
          })
        );
        return true;
      }

      if (!signinData.carrierSignUSPSClientId) {
        dispatch(
          setSigninData({
            ...signinData,
            carrierSignUSPSClientIdError: true,
          })
        );
        return true;
      }

      if (!signinData.carrierSignUSPSClientSecret) {
        dispatch(
          setSigninData({
            ...signinData,
            carrierSignUSPSClientSecretError: true,
          })
        );
        return true;
      }
    }

    if (signinData.carrier.ups) {
      if (!signinData.carrierSignUPSUsername) {
        dispatch(
          setSigninData({
            ...signinData,
            carrierSignUPSUsernameError: true,
          })
        );
        return true;
      }
      if (!signinData.carrierSignUPSPassword) {
        dispatch(
          setSigninData({
            ...signinData,
            carrierSignUPSPasswordError: true,
          })
        );
        return true;
      }
    }

    if (signinData.carrier.fedex) {
      if (!signinData.carrierSignFedexUsername) {
        dispatch(
          setSigninData({
            ...signinData,
            carrierSignFedexUsernameError: true,
          })
        );
        return true;
      }
      if (!signinData.carrierSignFedexPassword) {
        dispatch(
          setSigninData({
            ...signinData,
            carrierSignFedexPasswordError: true,
          })
        );
        return true;
      }
    }

    if (signinData.carrier.dhl) {
      if (!signinData.carrierSignDHLUsername) {
        dispatch(
          setSigninData({
            ...signinData,
            carrierSignDHLUsernameError: true,
          })
        );
        return true;
      }
      if (!signinData.carrierSignDHLPassword) {
        dispatch(
          setSigninData({
            ...signinData,
            carrierSignDHLPasswordError: true,
          })
        );
        return true;
      }
    }
  };

  const checkSignManager = () => {
    if (!signinData.managerSignMagentoUrl && signinData.manager === "magento") {
      dispatch(
        setSigninData({
          ...signinData,
          managerSignMagentoUrlErrors: true,
        })
      );
      return true;
    }

    if (signinData.managerSignMagentoUrl) {
      if (
        checkUrl(signinData.managerSignMagentoUrl) &&
        signinData.manager === "magento"
      ) {
        dispatch(
          setSigninData({
            ...signinData,
            managerSignMagentoUrlErrors: true,
            managerSignMagentoUrlNotValid: true,
          })
        );
        return true;
      }
    }

    if (
      !signinData.managerSignMagentoToken &&
      signinData.manager === "magento"
    ) {
      dispatch(
        setSigninData({
          ...signinData,
          managerSignMagentoTokenErrors: true,
        })
      );
      return true;
    }

    if (
      !signinData.managerSignShopifyStoreUrl &&
      signinData.manager === "shopify"
    ) {
      dispatch(
        setSigninData({
          ...signinData,
          managerSignShopifyStoreUrlErrors: true,
        })
      );
      return true;
    }

    if (signinData.managerSignShopifyStoreUrl) {
      if (
        checkUrl(signinData.managerSignShopifyStoreUrl) &&
        signinData.manager === "shopify"
      ) {
        dispatch(
          setSigninData({
            ...signinData,
            managerSignShopifyStoreUrlErrors: true,
            managerSignShopifyStoreUrlNotValid: true,
          })
        );
        return true;
      }
    }

    if (
      !signinData.managerSignShopifyApiKey &&
      signinData.manager === "shopify"
    ) {
      dispatch(
        setSigninData({
          ...signinData,
          managerSignShopifyApiKeyErrors: true,
        })
      );
      return true;
    }

    if (
      !signinData.managerSignShipStationApiKey &&
      signinData.manager === "shipstation"
    ) {
      dispatch(
        setSigninData({
          ...signinData,
          managerSignShipStationApiKeyErrors: true,
        })
      );
      return true;
    }

    if (
      !signinData.managerSignShipStationApiSecret &&
      signinData.manager === "shipstation"
    ) {
      dispatch(
        setSigninData({
          ...signinData,
          managerSignShipStationApiSecretErrors: true,
        })
      );
      return true;
    }
  };

  const checkUrl = (url) => {
    if (
      !/https:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/.test(
        url
      )
    )
      return true;
    return false;
  };

  const checkBillingData = () => {
    if (
      !signinData.creditCard ||
      signinData.creditCard.length < 15 ||
      signinData.creditCard.length > 16
    ) {
      dispatch(setSigninData({ ...signinData, creditCardError: true }));
      return true;
    }

    if (!signinData.cvv) {
      dispatch(setSigninData({ ...signinData, cvvError: true }));
      return true;
    }

    if (signinData.cvv.length < 3) {
      dispatch(setSigninData({ ...signinData, cvvError: true }));
      return true;
    }

    if (!signinData.expirationMonth) {
      dispatch(setSigninData({ ...signinData, expirationError: true }));
      return true;
    }

    if (!signinData.expirationYear) {
      dispatch(setSigninData({ ...signinData, expirationError: true }));
      return true;
    }

    if (!signinData.fullName) {
      dispatch(setSigninData({ ...signinData, fullNameError: true }));
      return true;
    }

    if (!signinData.billingAddr) {
      dispatch(setSigninData({ ...signinData, billingAddrError: true }));
      return true;
    }

    if (!signinData.city) {
      dispatch(setSigninData({ ...signinData, cityError: true }));
      return true;
    }

    if (!signinData.zipcode) {
      dispatch(setSigninData({ ...signinData, zipcodeError: true }));
      return true;
    }

    if (!signinData.state) {
      dispatch(setSigninData({ ...signinData, stateError: true }));
      return true;
    }

    if (!signinData.country) {
      dispatch(setSigninData({ ...signinData, countryError: true }));
      return true;
    }
  };

  const handleNextClick = () => {
    switch (step) {
      case 0:
        if (!signinData.carrier) {
          dispatch(setSigninData({ ...signinData, carrierError: true }));
          return;
        }
        // if(Object.keys(userInfo).length>1)
        // {
        //   dispatch(setSigninData({
        //     ...signinData,
        //     email: userInfo.email,
        //     phone: userInfo.phone_number,
        //     user_id: userInfo.user_id }));
        // }
        navigate("order-manager");
        setStep(1);
        break;

      case 1:
        if (!signinData.manager) {
          dispatch(setSigninData({ ...signinData, manageError: true }));
          return;
        }
        navigate("signin-carrier");
        setStep(2);
        break;

      case 2:
        if (checkSignCarrier()) return;
        navigate("signin-manager");
        setStep(3);
        break;

      case 3:
        if (checkSignManager()) return;
        navigate("plans");
        setStep(4);
        break;

      case 4:
        if (!signinData.plan) {
          dispatch(setSigninData({ ...signinData, plansError: true }));
          return;
        }
        navigate("billing");
        setStep(5);
        break;

      case 5:
        if (checkBillingData()) return;
        const data = {
          type: "card",
          email: signinData.email,
          phone: signinData.phone,
          first_name: signinData.fullName,
          card_no: signinData.creditCard,
          exp_month: signinData.expirationMonth,
          exp_year: signinData.expirationYear,
          cvv: signinData.cvv,
          billing: {
            city: signinData.city,
            address: signinData.billingAddr,
            country: signinData.country,
            zip_code: signinData.zipcode,
          },
        };
        dispatch(
          setSigninData({ ...signinData, isOnboardingPaymentCardLoad: true })
        );
        createPaymentMethod(data, userInfo, "onBoardingPayment")
          .then((res) => {
            dispatch(
              setSigninData({
                ...signinData,
                ...res.data,
                cardError: "",
                isOnboardingPaymentCardLoad: false,
              })
            );
            setStep(6);
            navigate("checkout");
          })
          .catch((e) =>
            dispatch(setSigninData({ ...signinData, cardError: e.message }))
          );
        break;

      case 6:
        handleOnBoarding();
        break;

      default:
        if (step >= 7) setStep(8);
        else setStep(0);
        break;
    }
  };

  const handleBackClick = () => {
    switch (step) {
      case 0:
        navigate("/login");
        setStep(0);
        break;

      case 1:
        setStep(0);
        navigate("carrier");
        break;

      case 2:
        setStep(1);
        navigate("order-manager");
        break;

      case 3:
        setStep(2);
        navigate("signin-carrier");
        break;

      case 4:
        navigate("signin-manager");
        setStep(3);
        break;

      case 5:
        navigate("plans");
        setStep(4);
        break;

      case 6:
        navigate("billing");
        setOnboardingError(false);
        setStep(5);
        break;

      default:
        if (step >= 6) {
          setStep(6);
          navigate("checkout");
        } else setStep(0);
        break;
    }
  };

  useEffect(() => {
    if (pathname.includes("order-manager")) setStep(1);
    if (pathname.includes("signin-carrier")) setStep(2);
    if (pathname.includes("signin-manager")) setStep(3);
    if (pathname.includes("plans")) setStep(4);
    if (pathname.includes("billing")) setStep(5);
    if (pathname.includes("checkout")) setStep(6);
  }, []);

  return (
    <Box className="signin-form-container">
      {isLoading && <CircularLoader />}

      <Box
        className="signin-chat"
        onClick={() =>
          window.open("https://calendly.com/beyondcarts/beyondcarts", "_blank")
        }
      >
        <p style={{ writingMode: "vertical-rl", color: "white" }}>
          We're online
        </p>
      </Box>

      {isDemoExpired ? (
        <Box
          sx={{
            display: "flex",
            paddingLeft: "20px",
            paddingRight: "20px",
            paddingTop: "20px",
            justifyContent: "space-between",
          }}
        >
          <img
            src="/assets/beyond-carts-logo.svg"
            alt="logo"
            style={{
              height: "40px",
              display: "block",
              cursor: "pointer",
            }}
            onClick={() => navigate("/login")}
          />
          <Stack
            direction="row"
            alignItems="center"
            spacing={{
              xs: 0.5,
              sm: 1,
            }}
          >
            <AccountPopover />
          </Stack>
        </Box>
      ) : (
        <div style={{ padding: "20px 0 0 20px" }}>
          <img
            src="/assets/beyond-carts-logo.svg"
            alt="logo"
            style={{
              height: "48px",
              display: "block",
              cursor: "pointer",
            }}
            onClick={() => navigate("/login")}
          />
        </div>
      )}

      <Stack>
        <Box className="signin-form-stepper-container">
          <FormStepper step={step} />
        </Box>

        <Box className="signin-form-spacer"></Box>

        <Outlet />

        {onBoardingError && (
          <Typography
            sx={{
              textAlign: "center",
              color: "red",
              fontSize: "20px",
            }}
          >
            {onBoardginErrorMjs}
          </Typography>
        )}

        <Box className="signin-form-btns-container">
          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            onClick={() => handleBackClick()}
            sx={{
              fontSize: { sx: 16, md: 18 },
              width: { xs: 140, sm: 218, lg: 265 },
              boxShadow: "none",
              backgroundColor: "grey.600",
              "&:hover": {
                backgroundColor: "primary.lightIcon",
              },
            }}
          >
            Back
          </LoadingButton>

          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            disabled={!signinData?.continueBtn}
            onClick={() => handleNextClick()}
            sx={{
              fontSize: { sx: 16, md: 18 },
              width: { xs: 140, sm: 218, lg: 265 },
              boxShadow: "none",
              backgroundColor: palette.secondary.main,
            }}
          >
            Continue
          </LoadingButton>
        </Box>
      </Stack>
    </Box>
  );
};

export default SigninFormContainer;
